import React, { ChangeEvent, FC, useCallback, useMemo } from 'react';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Box, Button, HStack, Text, VStack } from '@chakra-ui/react';

import { FloatingInputField } from '@payler/ui-components';
import { TextStyles } from '@payler/ui-theme';
import { BankLoader } from '../../components/BankLoader/BankLoader';
import {
  BankModalBody,
  BankModalFooter,
  BankModalTitle,
  ModalHeaderActions,
} from '../../components/BankModal/BankModal';
import ExchangeDropdownField from '../../components/ExchangeDropdown/ExchangeDropdownField';
import { useLayoutContext } from '../../context/LayoutContextProvider';
import { useAccountsQuery } from '../../hooks/accounts/queries';
import { useLanguageFeatures } from '../../hooks/use-language-features';
import { ArrowDownIcon, ArrowIcon } from '../../icons';
import {
  TExchangeSelectForm,
  useExchangeWizardContext,
} from './ExchangeWizard';
import {
  getAccountOptions,
  getFilteredAccounts,
  removeThousandSeparatorsAndSuffix,
} from './helpers';
import { useFormContext } from 'react-hook-form';

export const ExchangeSelect: FC = () => {
  const { t } = useTranslation();
  const { formatNumber } = useLanguageFeatures();
  const {
    amount,
    setAmount,
    exchangeRate,
    setStep,
    convertedAmount,
    detailedExchangeRate,
    senderAccount,
    setSenderAccount,
    receiverAccount,
    setReceiverAccount,
    isCommissionFetching,
  } = useExchangeWizardContext();

  const { isMobile } = useLayoutContext();

  const { data: accounts } = useAccountsQuery();

  const methods = useFormContext<TExchangeSelectForm>();
  const {
    formState: { errors },
    trigger,
    clearErrors,
  } = methods;

  const handleSubmit = () => {
    trigger(['amount', 'receiverAccId', 'senderAccId']).then(
      (isValid) => isValid && setStep('confirmation'),
    );
  };

  const senderAccountOptions = useMemo(() => {
    return getAccountOptions(senderAccount?.id, accounts, formatNumber);
  }, [senderAccount?.id, accounts, formatNumber]);

  const receiverAccountOptions = useMemo(() => {
    const availableReceiverAccounts = getFilteredAccounts(
      senderAccount?.currency,
      accounts,
    );
    return getAccountOptions(
      receiverAccount?.id,
      availableReceiverAccounts,
      formatNumber,
    );
  }, [senderAccount, receiverAccount, accounts, formatNumber]);

  const onAccountChange = useCallback(
    (selectedAccountId: string, isSenderSelector: boolean) => {
      if (errors) {
        clearErrors('amount');
      }
      const selectedAccount = accounts?.find(
        (account) => selectedAccountId === account.id,
      );
      const setAccount = isSenderSelector
        ? setSenderAccount
        : setReceiverAccount;
      setAccount(selectedAccount);
    },
    [errors, accounts, setSenderAccount, setReceiverAccount, clearErrors],
  );

  const omAmountChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (errors) {
        clearErrors('amount');
      }

      setAmount(Number(removeThousandSeparatorsAndSuffix(e.target.value)));
    },
    [errors, clearErrors, setAmount],
  );
  return (
    <>
      <ModalHeaderActions />
      <BankModalTitle
        title={t('accounts:exchange.selectStep.title')}
        description={t('accounts:exchange.selectStep.description')}
      />
      <BankModalBody>
        <VStack spacing={3}>
          {isMobile ? (
            <VStack w="full">
              <Box w="inherit">
                <ExchangeDropdownField
                  fieldName="senderAccId"
                  options={senderAccountOptions || []}
                  onChange={(id) => onAccountChange(id as string, true)}
                />
              </Box>
              <HStack w="inherit" justifyContent="space-between" px={1}>
                <Text>
                  {t('accounts:exchange.selectStep.exchangeDirection', {
                    senderCurrency: senderAccount?.currency.toUpperCase(),
                    receiverCurrency: receiverAccount?.currency.toUpperCase(),
                  })}
                </Text>
                <Box>
                  <ArrowDownIcon />
                </Box>
              </HStack>
              <Box w="inherit">
                <ExchangeDropdownField
                  fieldName="receiverAccId"
                  options={receiverAccountOptions || []}
                  onChange={(id) => onAccountChange(id as string, false)}
                />
              </Box>
            </VStack>
          ) : (
            <HStack spacing={1} width="full" justifyContent="space-between">
              <Box w="full">
                <ExchangeDropdownField
                  fieldName="senderAccId"
                  options={senderAccountOptions || []}
                  onChange={(id) => onAccountChange(id as string, true)}
                />
              </Box>
              <Box w="24px">
                <ArrowIcon />
              </Box>
              <Box w="full">
                <ExchangeDropdownField
                  fieldName="receiverAccId"
                  options={receiverAccountOptions || []}
                  onChange={(id) => onAccountChange(id as string, false)}
                />
              </Box>
            </HStack>
          )}
          <FloatingInputField
            value={amount}
            name="amount"
            variant="currency"
            currencySuffix={
              amount ? senderAccount?.currency.toUpperCase() : undefined
            }
            label={t('accounts:exchange.selectStep.amount')}
            onChange={(e) => omAmountChange(e)}
            allowNegative={false}
          />
          {exchangeRate && !isCommissionFetching ? (
            <HStack w="full" justifyContent="space-between">
              <Box>
                <Text
                  textStyle={TextStyles.Caption12Regular}
                  color="primary.350"
                >
                  {t('accounts:exchange.selectStep.youGet')}
                </Text>
                <Text textStyle={TextStyles.tables}>
                  {`${convertedAmount} ${receiverAccount?.currency.toUpperCase()}`}
                </Text>
              </Box>

              <Box>
                <Text
                  textStyle={TextStyles.Caption12Regular}
                  color="primary.350"
                  textAlign="right"
                >
                  {t('accounts:exchange.selectStep.exchangeRate')}
                </Text>
                <Text textStyle={TextStyles.tables}>
                  {detailedExchangeRate}
                </Text>
              </Box>
            </HStack>
          ) : (
            <BankLoader />
          )}
        </VStack>
      </BankModalBody>
      <BankModalFooter>
        <Button
          w="full"
          onClick={handleSubmit}
          isDisabled={!isEmpty(errors) || !exchangeRate}
        >
          {t('common:continue')}
        </Button>
      </BankModalFooter>
    </>
  );
};
