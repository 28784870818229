import createLogger from 'debug';
import { useCallback, useMemo } from 'react';
import { isNil } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import { useApi } from '../state/api';

const log = createLogger('hooks:currencies-info');

const CURRENCIES_STALE_TIME = 24 * 60 * 6e5;
const useCurrenciesQuery = () => {
  const api = useApi();
  return useQuery(
    ['currencies'],
    () => {
      return api.getCurrenciesInfo();
    },
    { staleTime: CURRENCIES_STALE_TIME },
  );
};

/**
 * Коды всех валют
 */
export const useAllCurrencies = () => {
  const { data } = useCurrenciesQuery();
  return data?.map((x) => x.code) ?? [];
};

/**
 * Разрядность валют
 */
const useCurrencyDecimalsInfo = (): Partial<Record<string, number>> => {
  const { data } = useCurrenciesQuery();
  return useMemo(
    () =>
      (data ?? []).reduce(
        (prev, current) => ({ ...prev, [current.code]: current.decimalDigits }),
        {},
      ),
    [data],
  );
};

/**
 * Символы валют
 */
const useCurrencySymbolsInfo = (): Partial<Record<string, string>> => {
  const { data } = useCurrenciesQuery();
  return useMemo(() => {
    return (data ?? []).reduce(
      (prev, current) => ({ ...prev, [current.code]: current.symbol }),
      {},
    );
  }, [data]);
};

/**
 * функции для работы со словарём валют
 */
export const useCurrencyCallbacks = () => {
  const symbols = useCurrencySymbolsInfo();
  const data = useCurrencyDecimalsInfo();
  const getCurrencyDecimalsCount = useCallback(
    (currency?: string | null) => {
      if (isNil(currency)) {
        log('WARN: getCurrencyDecimalsCount currency undefined or null');
        return 2;
      }
      return data[currency.toUpperCase()] ?? 2;
    },
    [data],
  );
  const getCurrencyUnitRate = useCallback(
    (currency?: string | null) => {
      if (isNil(currency)) {
        log('WARN: getCurrencyUnitRate currency undefined or null');
      }
      return 10 ** getCurrencyDecimalsCount(currency);
    },
    [getCurrencyDecimalsCount],
  );

  const getCurrencySymbol = useCallback(
    (currency?: string | null) => {
      if (isNil(currency)) {
        log('WARN: getCurrencySymbol currency undefined or null');
        return '';
      }
      return symbols[currency.toUpperCase()] ?? '';
    },
    [symbols],
  );
  return {
    getCurrencyUnitRate,
    getCurrencyDecimalsCount,
    getCurrencySymbol,
  };
};
