import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { EventStatus } from '../../components/EventStatus/EventStatus';

export const TransferDemo = ({ close }: { close: VoidFunction }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <EventStatus
      statusType="success"
      title={t('accounts:transferMoney.completeOnboardingProcess')}
      description={t(
        'accounts:transferMoney.yourAccountIsCurrentlyNotActivated',
      )}
      onCloseButtonClick={() => {
        close();
      }}
      onActionButtonClick={() => {
        close();
        navigate('/');
      }}
    />
  );
};
