import React from 'react';
import { CrossIcon } from '@payler/ui-icons';
import { IconButton } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export const CloseButton: React.FC<{ onClick: VoidFunction }> = ({
  onClick,
}) => {
  const { t } = useTranslation();
  return (
    <IconButton
      aria-label={t('accounts:accountsDrawer.ariaLabels.closeDrawer')}
      variant="outlined"
      w={6}
      h={6}
      border="none"
      icon={<CrossIcon />}
      onClick={onClick}
      data-testid="button_close"
    />
  );
};
