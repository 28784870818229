import {
  TOnboardingStepDto,
  TStageTypeDto,
  TStepTypeDto,
} from '@payler/api/client-office';
import { useGetAxiosError } from '@payler/bank-utils';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { lowerFirst } from 'lodash';
import { useClientOfficeConfig } from '../../config/ConfigProvider';
import { checkStartOnboardingError } from '../../pages/OnboardingPage/OnboardingPage';
import { useApi } from '../../state/api';

/**
 * Запрос этапов прохождения онбординга. Игнорирует ошибку старта онбординга. Сразу сортирует
 * по порядку.
 */
export const useOnboardingStagesQuery = ({
  enabled = true,
  refetchInterval,
  isRefetchingAllowed = true,
}: {
  enabled?: boolean;
  /** Периодический запрос этапов онбординга. В демо кабинете используется чтобы отображать
   * актуальный статус прохождения этапов. */
  refetchInterval?: number;
  /** При прохождении онбординга не нужно перезапрашивать из-за zoho форм */
  isRefetchingAllowed?: boolean;
}) => {
  const api = useApi();
  const getError = useGetAxiosError();
  return useQuery(
    ['onboarding', 'stages'],
    async () => await api.getOnboardingStages(),
    {
      enabled,
      refetchInterval,
      refetchOnMount: isRefetchingAllowed,
      refetchOnReconnect: isRefetchingAllowed,
      refetchOnWindowFocus: isRefetchingAllowed,
      retry: (failureCount, error) => {
        return (
          !checkStartOnboardingError(getError(error).errorCode) &&
          failureCount <= 3
        );
      },
      useErrorBoundary: (error) => {
        return !checkStartOnboardingError(getError(error).errorCode);
      },
      select: ({ stages, ...rest }) => ({
        stages: stages.sort((a, b) => a.orderNumber - b.orderNumber),
        ...rest,
      }),
    },
  );
};

export const useStartOnboarding = () => {
  const api = useApi();
  const client = useQueryClient();
  return useMutation(['startOnboarding'], () => api.startOnboarding(), {
    onSuccess: () => {
      client.invalidateQueries(['onboarding']);
    },
  });
};

export const useOnboardingStageStepQuery = (
  stage?: TStageTypeDto,
  refetchInterval?: number,
) => {
  const api = useApi();
  return useQuery(
    ['onboarding', 'step', stage],
    async () => await api.getOnboardingStageStep(stage),
    {
      enabled: !!stage,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchInterval,
    },
  );
};

export const useOnboardingStepQuery = (step?: TStepTypeDto) => {
  const api = useApi();
  return useQuery(
    ['onboarding', 'step', step],
    async () => await api.getOnboardingStep(step),
    {
      enabled: [
        'briefForm',
        'preScoringForm',
        'documentsForm',
        'creatingAccount',
      ].includes(lowerFirst(step)),
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );
};
export const useOnboardingStepMockQuery = (step?: TStepTypeDto) => {
  const api = useApi();
  const { onboardingMockUrls } = useClientOfficeConfig();
  return useQuery(
    ['onboarding', 'step', step],
    async () => {
      const token = await api.getToken();
      let response: TOnboardingStepDto;
      if (step?.toLowerCase() === 'briefForm'.toLowerCase()) {
        response = {
          iframeUrl: `${onboardingMockUrls?.briefForm}?token=${token}&nextStep=documentsForm`,
          type: step,
          status: 'notStarted',
        };
      } else if (step?.toLowerCase() === 'documentsForm'.toLowerCase()) {
        response = {
          iframeUrl: `${onboardingMockUrls?.documentsForm}?token=${token}&nextStep=preScoringForm`,
          type: step,
          status: 'notStarted',
        };
      } else if (step?.toLowerCase() === 'preScoringForm'.toLowerCase()) {
        response = {
          iframeUrl: `${onboardingMockUrls?.preScoringForm}?token=${token}&nextStep=creatingAccount`,
          type: step,
          status: 'notStarted',
        };
      }
      return new Promise<TOnboardingStepDto>((resolve) => {
        resolve(response);
      });
    },
    {
      enabled: !!step,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );
};

export const useOnboardingPaymentQuery = () => {
  const api = useApi();
  const getError = useGetAxiosError();
  return useQuery(
    ['onboarding', 'payment'],
    async () => await api.getOnboardingPayment(),
    {
      retryDelay: 5000,
      retry: (failureCount, error) =>
        getError(error).errorCode !== 'FAILED_CREATE_PAYMENT' &&
        (getError(error).errorCode === 'CLIENT_NOT_FOUND'
          ? true
          : failureCount <= 3),
      useErrorBoundary: (error) =>
        !['FAILED_CREATE_PAYMENT', 'CLIENT_NOT_FOUND'].includes(
          getError(error).errorCode,
        ),
      refetchOnWindowFocus: false,
    },
  );
};
