import { Box, HStack, Icon, Text, Tooltip, VStack } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import { FC, FunctionComponent, HTMLAttributes, SVGProps } from 'react';

export interface AccountCardProps extends HTMLAttributes<HTMLDivElement> {
  caption: string;
  subCaption: string;
  selected?: boolean;
  isHorizontal?: boolean;
  icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  overlayIcon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  overlayIconColor?: string;
  isDisabled?: boolean;
  withTooltip?: boolean;
}

export const AccountCard: FC<AccountCardProps> = ({
  caption,
  subCaption,
  selected,
  isHorizontal,
  icon,
  isDisabled,
  onClick,
  withTooltip = true,
  overlayIcon,
  overlayIconColor,
  color,
  ...props
}: AccountCardProps) => {
  const DynamicStack = isHorizontal ? HStack : VStack;

  return (
    <Box
      w="100%"
      minW="144px"
      h={isHorizontal ? '72px' : '126px'}
      borderRadius={1.5}
      cursor={!isDisabled ? 'pointer' : 'default'}
      bgColor={selected ? 'brands.100' : 'secondary.500'}
      border={selected ? '4px solid' : undefined}
      borderColor={selected ? 'secondary.500' : undefined}
      onClick={!isDisabled ? onClick : undefined}
      {...props}
    >
      <DynamicStack spacing={2} alignItems="start" p={selected ? 1.5 : 2}>
        <Box position="relative" w="40px" h="40px">
          <Icon w="40px" h="40px" as={icon} color={color} />
          {overlayIcon && (
            <Icon
              position="absolute"
              right="-2px"
              bottom="-2px"
              w="18px"
              h="18px"
              as={overlayIcon}
              color={overlayIconColor}
            />
          )}
        </Box>
        <Box maxW="full" overflow="hidden">
          {withTooltip ? (
            <Tooltip label={caption}>
              <Text
                textStyle={TextStyles.Subtitle14Semibold}
                maxW="full"
                overflow="hidden"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
              >
                {caption}
              </Text>
            </Tooltip>
          ) : (
            <Text
              textStyle={TextStyles.Subtitle14Semibold}
              maxW="full"
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
            >
              {caption}
            </Text>
          )}
          {withTooltip ? (
            <Tooltip label={subCaption}>
              <Text
                textStyle={TextStyles.Caption12Regular}
                color="primary.350"
                maxW="full"
                overflow="hidden"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
              >
                {subCaption}
              </Text>
            </Tooltip>
          ) : (
            <Text
              textStyle={TextStyles.Caption12Regular}
              color="primary.350"
              maxW="full"
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
            >
              {subCaption}
            </Text>
          )}
        </Box>
      </DynamicStack>
    </Box>
  );
};
