import { Button, ButtonProps, Text, VStack } from '@chakra-ui/react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EventStatus } from '../../components/EventStatus/EventStatus';
import { useSaveRecipientMutation } from '../../hooks/recipients/mutations';
import { useLanguageFeatures } from '../../hooks/use-language-features';
import { useTransferWizardContext } from './TransferWizard';
import { useHandleToastError } from '@payler/bank-utils';
import { useTransferStatementQuery } from '../../hooks/reports/queries';
import { TextStyles } from '@payler/ui-theme';

export const TransferSuccess = ({ close }: { close: VoidFunction }) => {
  const { t } = useTranslation();
  const { formatAmountByCurrency } = useLanguageFeatures();
  const {
    transferDetails,
    senderAccount,
    isNewRecipientState,
    recipient,
    setMode,
    transferId,
    isSelfTransfer,
  } = useTransferWizardContext();
  const { mutate: saveRecipient, isLoading: isSaveRecipientLoading } =
    useSaveRecipientMutation();

  const handleToastError = useHandleToastError();
  const { handleTransferStatementQuery, loading: isDownloadStatementLoading } =
    useTransferStatementQuery();

  const handleDownloadStatement = useCallback(async () => {
    if (transferId) {
      try {
        await handleTransferStatementQuery(transferId);
      } catch (e) {
        handleToastError(e);
      }
    }
  }, [handleToastError, handleTransferStatementQuery, transferId]);

  const onSaveButtonClick = useCallback(() => {
    saveRecipient(recipient?.id || '', {
      onSuccess: () => {
        setMode('recipientSuccess');
      },
    });
  }, [recipient?.id, saveRecipient, setMode]);

  const addRecipientProps =
    (isNewRecipientState || !recipient?.isVisible) && !isSelfTransfer
      ? {
          actionButtonTitle: t('accounts:transferMoney.saveRecipient'),
          onActionButtonClick: onSaveButtonClick,
          actionButtonProps: {
            isLoading: isSaveRecipientLoading,
          } as ButtonProps,
        }
      : undefined;

  const description = useMemo(
    () => (
      <VStack>
        <Text textStyle={TextStyles.BodyText16Regular} color="primary.400">
          {t('accounts:transferMoney.transferEnqueued')}
        </Text>
        {transferId && (
          <Button
            variant="link"
            as="a"
            onClick={handleDownloadStatement}
            textStyle={TextStyles.link}
            isDisabled={isDownloadStatementLoading}
          >
            {isDownloadStatementLoading
              ? t('accounts:transferMoney.downloading')
              : t('accounts:transferMoney.downloadPdf')}
          </Button>
        )}
      </VStack>
    ),
    [handleDownloadStatement, isDownloadStatementLoading, t, transferId],
  );

  return (
    <EventStatus
      statusType="success"
      title={formatAmountByCurrency(
        transferDetails?.amount ?? 0,
        senderAccount?.currency ?? '',
      )}
      description={description}
      onCloseButtonClick={() => {
        close();
      }}
      closeButtonProps={{ variant: 'secondary' }}
      {...addRecipientProps}
    />
  );
};
