export const recipients = {
  addRecipient: 'Add recipient',
  type: 'Type',
  corporate: 'Corporate',
  individual: 'Individual',
  internal: 'Internal',
  infoDrawer: {
    transferMoney: 'Transfer money',
    address: 'Registration Address',
    currency: 'Currency',
    bankCountry: 'Bank Country',
    bankName: 'Bank Name',
    iban: 'IBAN',
    accountNumber: 'Account Number',
    bic: 'BIC',
    info: 'Info',
    noAccountsInfo:
      'Sending to the recipient is not possible as you do not have accounts in this currency',
    noAvailableAccountsInfo:
      'Sending to the recipient is not possible as you do not have any available accounts in this currency',
  },
  addModal: {
    recipientInfo: 'Recipient Info',
    accountInfo: 'Account info',
    description: 'Please enter below the recipient details.',
    accountCurrency: 'Account currency',
    bankCountry: 'Bank country',
    bankName: 'Bank name',
    accountIdentifier: 'Account Number / IBAN',
    bic: 'BIC',
    bicNumber: 'BIC Number',
    accHolderName: 'Account Holder Name',
    legalName: 'Legal name',
    firstName: 'First name',
    lastName: 'Last name',
    registrationAddress: 'Registration Address',
    allFieldsRequired: 'All fields are required',
    selectCountry: 'Select country',
    zipPostalCode: 'ZIP/Postal Code',
    cityTown: 'City/Town',
    street: 'Street',
    successDescription: 'Successfully added to recipients list',
    accountInfoMessage:
      "The recipient's account must be the account of the Payler's customer",
  },
  name: 'Name',
  country: 'Country',
  bic: 'BIC',
  bankInfo: 'Bank info',
};
