import React from 'react';
import {
  BankModal,
  BankModalTitle,
  ModalHeaderActions,
} from '../../components/BankModal/BankModal';
import { AccountStatementForm } from '../../forms/AccountStatement/AccountStatementForm';
import { useTranslation } from 'react-i18next';
import { useBankBreakpointValue } from '../../hooks/use-bank-breakpoint-value';
import {
  useRecoilBooleanWithPayload,
  useRecoilBooleanWithPayloadValue,
} from '@payler/bank-utils';

const KEY = 'modals:AccountStatementModal';
export const useShowAccountStatementModal = () =>
  useRecoilBooleanWithPayload<string>(KEY).on;
export const useCloseAccountStatementModal = () =>
  useRecoilBooleanWithPayload<string>(KEY).off;
export const useIsOpenAccountStatementModal = () =>
  useRecoilBooleanWithPayloadValue<string>(KEY);

export const AccountStatementModal: React.FC = () => {
  const { t } = useTranslation();
  const { isOpen } = useIsOpenAccountStatementModal();
  const close = useCloseAccountStatementModal();
  const isMobile = useBankBreakpointValue({ base: true, sm: false });

  return (
    <BankModal close={close} open={isOpen}>
      {isMobile && <ModalHeaderActions />}
      <BankModalTitle title={t('accounts:accountStatement.title')} />
      <AccountStatementForm />
    </BankModal>
  );
};
