import { Flex, HStack, VStack, Text } from '@chakra-ui/react';
import { TRecipientDto } from '@payler/api/client-office';
import {
  CardContent,
  ErrorBoundary,
  NoData,
  ThinDivider,
} from '@payler/ui-components';
import { TextStyles } from '@payler/ui-theme';
import React from 'react';
import { ArrowButton } from '../ArrowButton/ArrowButton';
import { LoaderBox } from '../LoaderBox/LoaderBox';
import {
  getRecipientTitle,
  useLocalizedRecipientType,
} from '../../helpers/recipient';
import { useRecipientIcon } from '../../hooks/recipients/icon';
import {
  useRecipientsQuery,
  useRecipientQueryPagerProps,
  usePrefetchRecipientsQuery,
  useSetRecipientIdToUrl,
} from '../../hooks/recipients/queries';
import { TableFooter } from '../../tables/TableFooter';

export const RecipientsBlock = () => {
  return (
    <CardContent
      p={0}
      bg="secondary.500"
      borderRadius={1.5}
      border="0.5px solid"
      borderColor="primary.100"
      overflow="hidden"
      width="100%"
    >
      <React.Suspense fallback={<LoaderBox variant="table" />}>
        <Recipients />
      </React.Suspense>
    </CardContent>
  );
};

const Recipients = () => {
  const paginationProps = useRecipientQueryPagerProps();
  usePrefetchRecipientsQuery(paginationProps.totalPages);

  return (
    <>
      <React.Suspense fallback={<LoaderBox />}>
        <RecipientsList />
      </React.Suspense>
      <ErrorBoundary>
        <React.Suspense fallback={null}>
          <TableFooter paginationProps={paginationProps} />
        </React.Suspense>
      </ErrorBoundary>
    </>
  );
};

const RecipientsList = () => {
  const setRecipientId = useSetRecipientIdToUrl();
  const { data } = useRecipientsQuery();
  const items = data?.data;
  if (!items?.length) return <NoData />;

  return (
    <VStack
      spacing={0}
      divider={<ThinDivider />}
      borderTopWidth="0.5px"
      borderTopStyle="solid"
      borderTopColor="primary.100"
      width="100%"
    >
      {items.map((data) => {
        return (
          <ArrowButton
            key={data.id}
            _hover={{
              bg: 'primary.25',
            }}
            onClick={() => {
              setRecipientId(data.id);
            }}
            cursor="pointer"
            data-testid="item_recipient"
          >
            <RecipientEntity data={data} />
          </ArrowButton>
        );
      })}
    </VStack>
  );
};

const RecipientEntity = ({ data }: { data: TRecipientDto }) => {
  const localizedType = useLocalizedRecipientType(data.recipientType);
  const title = getRecipientTitle(data);
  const icon = useRecipientIcon(title);

  return (
    <HStack spacing={2}>
      <Flex alignItems="center" position="relative">
        {icon}
      </Flex>
      <VStack spacing={0} alignItems="baseline">
        <Text textStyle={TextStyles.tables} color="primary.500">
          {title}
        </Text>
        <Text textStyle={TextStyles.Caption12Regular} color="primary.350">
          {localizedType}
        </Text>
      </VStack>
    </HStack>
  );
};
