import { useTranslation } from 'react-i18next';

export const useRecipientBankInfo = ({
  name,
  country,
  bic,
}: {
  name?: string | null;
  country?: string | null;
  bic?: string | null;
}) => {
  const { t } = useTranslation();
  const bankDetails: { label: string; value: string }[] = [];

  if (name) bankDetails.push({ label: t('recipients:name'), value: name });
  if (country)
    bankDetails.push({ label: t('recipients:country'), value: country });
  if (bic) bankDetails.push({ label: t('recipients:bic'), value: bic });
  const label =
    `${t('recipients:bankInfo')}: ` +
    bankDetails.map((item) => item.label).join(', ');
  const value = bankDetails.map((item) => item.value).join(', ');
  return {
    label,
    value,
  };
};
