import { useTranslation } from 'react-i18next';
import { InfoItem } from './InfoItem';
import { MayBe } from '@payler/bank-utils';

export const SelfTransferAccountInfo = ({
  senderAccountNumber,
  receiverAccountNumber,
}: {
  senderAccountNumber: MayBe<string>;
  receiverAccountNumber: MayBe<string>;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <InfoItem
        label={t('transfers:senderAccount')}
        value={senderAccountNumber}
      />
      <InfoItem
        label={t('transfers:receiverAccount')}
        value={receiverAccountNumber}
      />
    </>
  );
};
