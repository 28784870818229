import { useApi } from '../../state/api';
import {
  useMutation,
  UseMutationOptions,
  useQuery,
} from '@tanstack/react-query';
import { useHandleToastError } from '@payler/bank-utils';

const CLIENT_STALE_TIME = 5 * 6e4;

export const useClientQuery = () => {
  const api = useApi();
  return useQuery(['client'], () => api.getClient(), {
    staleTime: CLIENT_STALE_TIME,
    suspense: true,
  });
};

export const useSendDeletionNotifications = (
  onSuccess: UseMutationOptions['onSuccess'],
) => {
  const api = useApi();
  const handleToastError = useHandleToastError();
  return useMutation({
    mutationFn: () => api.sendDeletionNotifications(),
    onSuccess,
    onError: handleToastError,
    useErrorBoundary: false,
  });
};
