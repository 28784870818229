import React, {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { TAccountDto, TFeeBaasProvider } from '@payler/api/client-office';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  useAccountByUrl,
  useAccountsQuery,
} from '../../hooks/accounts/queries';
import {
  useCurrencyRateQuery,
  useTransferFeeQuery,
} from '../../hooks/transfers/queries';
import { useProfileInfo } from '../../hooks/use-profile-info';
import { getRiskLevelDecoding } from '../../riskLevel';
import { ExchangeConfirmation } from './ExchangeConfirmation';
import { ExchangeSelect } from './ExchangeSelect';
import { ExchangeSuccess } from './ExchangeSuccess';
import {
  calculateRateWithCommission,
  getDefaultReceiverAccount,
  prepareRateRequestDto,
} from './helpers';

type TExchangeStep = 'select' | 'confirmation' | 'success';

type TExchangeWizardContext = {
  step: TExchangeStep;
  setStep: Dispatch<SetStateAction<TExchangeStep>>;
  amount: number | undefined;
  setAmount: Dispatch<SetStateAction<number | undefined>>;
  exchangeRate: number | undefined;
  detailedExchangeRate: string;
  convertedAmount: number;
  setConvertedAmount: Dispatch<SetStateAction<number>>;
  senderAccount: TAccountDto | undefined;
  setSenderAccount: Dispatch<SetStateAction<TAccountDto | undefined>>;
  receiverAccount: TAccountDto | undefined;
  setReceiverAccount: Dispatch<SetStateAction<TAccountDto | undefined>>;
  isCommissionFetching: boolean;
};

const ExchangeWizardContext = createContext<TExchangeWizardContext>(
  {} as TExchangeWizardContext,
);

export type TExchangeSelectForm = {
  amount: number | undefined;
  senderAccId: string | undefined;
  receiverAccId: string | undefined;
};

const useResolver = (senderAmount: number | undefined) => {
  const { t } = useTranslation();

  return yupResolver(
    yup.object().shape({
      amount: yup
        .number()
        .typeError(t('validate.required'))
        .required(t('validate.required'))
        .test((value, ctx) => {
          if (!value)
            return ctx.createError({ message: t('validate.required') });
          if (value < 10) {
            return ctx.createError({
              message: t('accounts:exchange.selectStep.greaterThan10'),
            });
          }
          if (senderAmount && value > senderAmount) {
            return ctx.createError({
              message: t('accounts:exchange.selectStep.notEnoughMoney'),
            });
          }
          return true;
        }),
      senderAccId: yup.string(),
      receiverAccId: yup.string(),
    }),
  );
};

export const ExchangeWizard: FC = () => {
  const selectedAccount = useAccountByUrl();
  const profile = useProfileInfo();

  const [step, setStep] = useState<TExchangeStep>('select');
  const [amount, setAmount] = useState<number | undefined>();
  const [exchangeRate, setExchangeRate] = useState<number>();
  const [convertedAmount, setConvertedAmount] = useState<number>(0);
  const [senderAccount, setSenderAccount] = useState<TAccountDto | undefined>(
    selectedAccount,
  );
  const [receiverAccount, setReceiverAccount] = useState<
    TAccountDto | undefined
  >();

  const { data: accounts } = useAccountsQuery();
  const riskLevel = getRiskLevelDecoding(profile?.RiskLevel);

  useEffect(() => {
    const selectedReceiverAccount =
      (senderAccount?.currency !== receiverAccount?.currency &&
        receiverAccount) ||
      getDefaultReceiverAccount(
        senderAccount?.id,
        senderAccount?.currency,
        accounts,
      );
    setReceiverAccount(selectedReceiverAccount);
  }, [senderAccount, accounts, receiverAccount, setReceiverAccount]);

  const detailedExchangeRate = useMemo(() => {
    return `1 ${senderAccount?.currency.toUpperCase()} = ${exchangeRate} ${receiverAccount?.currency.toUpperCase()}`;
  }, [senderAccount, receiverAccount, exchangeRate]);

  const { data: transferFee, isFetching: isCommissionFetching } =
    useTransferFeeQuery({
      BaasProvider: senderAccount?.baasProvider.shortName as TFeeBaasProvider,
      TransferType: 'fx',
      Amount: amount,
      Currency: senderAccount?.currency,
    });

  const setExchangeRateWithCommission = useCallback(
    (rate: number | undefined) => {
      if (transferFee?.fxPercentFee && rate) {
        const rateWithCommission = calculateRateWithCommission(
          rate,
          transferFee.fxPercentFee,
        );
        setExchangeRate(rateWithCommission);
      } else {
        setExchangeRate(rate);
      }
    },
    [transferFee, setExchangeRate],
  );

  useEffect(() => {
    if (exchangeRate) {
      const roundedAmount = amount
        ? Number((amount * exchangeRate).toFixed(2))
        : 0;
      setConvertedAmount(roundedAmount);
    }
  }, [amount, exchangeRate]);

  const currencyRateParams = useMemo(() => {
    const rateRequestDto = prepareRateRequestDto(
      senderAccount?.currency,
      receiverAccount?.currency,
      amount,
      riskLevel,
    );
    const isRefetchData = step === 'select' || step === 'confirmation';
    return {
      rateRequestDto,
      isRefetchData,
    };
  }, [
    amount,
    senderAccount?.currency,
    receiverAccount?.currency,
    riskLevel,
    step,
  ]);

  const { data: currencyData } = useCurrencyRateQuery(currencyRateParams);

  useEffect(() => {
    setExchangeRateWithCommission(currencyData?.rate);
  }, [currencyData?.rate, setExchangeRateWithCommission]);

  const methods = useForm<TExchangeSelectForm>({
    resolver: useResolver(senderAccount?.amount),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    values: {
      amount: amount,
      senderAccId: senderAccount?.id,
      receiverAccId: receiverAccount?.id,
    },
  });

  const ctx: TExchangeWizardContext = useMemo(() => {
    return {
      step,
      setStep,
      amount,
      setAmount,
      exchangeRate,
      convertedAmount,
      setConvertedAmount,
      senderAccount,
      setSenderAccount,
      receiverAccount,
      setReceiverAccount,
      detailedExchangeRate,
      isCommissionFetching,
    };
  }, [
    step,
    setStep,
    amount,
    setAmount,
    exchangeRate,
    convertedAmount,
    setConvertedAmount,
    senderAccount,
    setSenderAccount,
    receiverAccount,
    setReceiverAccount,
    detailedExchangeRate,
    isCommissionFetching,
  ]);

  return (
    <ExchangeWizardContext.Provider value={ctx}>
      <FormProvider {...methods}>
        {step === 'select' && <ExchangeSelect />}
        {step === 'confirmation' && <ExchangeConfirmation />}
        {step === 'success' && <ExchangeSuccess />}
      </FormProvider>
    </ExchangeWizardContext.Provider>
  );
};

export const useExchangeWizardContext = () => useContext(ExchangeWizardContext);
