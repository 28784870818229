export const onboarding = {
  successPage: {
    successTitle: 'The information you provided is being processed',
    successInfo1:
      'Make sure that all shareholders/ultimate beneficial owners (UBOs) complete the KYC process.',
    successInfo2:
      'As we wrap up the onboarding processes, anticipate an email from us with further details. This usually takes between 24 hours and 2 weeks.',
  },
  paymentPage: {
    title: 'Application Fee',
    subtitle:
      'Before we can open your Payler Business Account, there is a one-time application fee. This fee includes:',
    maintenanceFee: 'Maintenance Fee',
    maintenanceFeeText:
      'Covers the cost of maintaining your account and providing ongoing support.',
    fastTrackReview: 'Fast-Track Review',
    fastTrackReviewText:
      'Speeds up the review process to get your account up and running faster.',
    kybKycCompliance: 'KYB/KYC Compliance',
    kybKycComplianceText:
      'Ensures that your account meets all Know Your Business/Know Your Customer requirements.',
    important: 'Important',
    importantText:
      "Please note that the application fee varies depending on the nature of your business. It's important to understand that this payment does not guarantee successful onboarding. We are committed to maintaining the highest standards of security and compliance, and all applications are subject to review.",
    loadingMessage:
      'At the moment the data of your questionnaire is being processed for billing purposes',
    buttonText: 'Pay {{price}} {{currency}}',
    failedCreatePaymentMessage:
      'Sorry, looks like we’re not ready to work with you yet',
  },
  creatingAccountPage: {
    title: 'Creating account',
    subtitle:
      'Almost there! Our robot assembly line is crafting your account piece by piece. 🤖🛠️',
  },
  rejectionPage: {
    title: 'Important note',
    text1:
      'Unfortunately, Payler is unable to provide services to companies or residences in the listed countries.',
    text2:
      'Rest assured, we value your interest, and we are actively exploring opportunities to expand our services to more locations.',
    text3:
      'We will keep your contact information on record and notify you as soon as Payler becomes available in additional countries. Thank you for your understanding and patience.',
    listTitle: 'List of prohibited countries',
    buttonText: 'Go back to the website',
  },
};
