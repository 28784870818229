import {
  TAccountDto,
  TransferStateValues,
  TransferSystemValues,
  TransferTypeValues,
} from '@payler/api/client-office';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLanguageFeatures } from '../../hooks/use-language-features';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  TDropdownOption,
  createDateFieldResolver,
} from '@payler/ui-components';
import dayjs from 'dayjs';
import { nullifyNaN } from '@payler/bank-utils';

export type TTransferFiltersForm = {
  accountId: string;
  currency: string;
  type: string;
  system: string;
  status: string[] | null;
  createdFrom: string;
  createdTo: string;
  amountFrom: number | null;
  amountTo: number | null;
};

export const EMPTY: TTransferFiltersForm = {
  accountId: '',
  currency: '',
  type: '',
  system: '',
  status: null,
  createdFrom: '',
  createdTo: '',
  amountFrom: null,
  amountTo: null,
};

export const errorsToFields = {
  AccountId: 'accountId',
  Currency: 'currency',
  Type: 'type',
  System: 'system',
  States: 'status',
  CreatedFrom: 'createdFrom',
  CreatedTo: 'createdTo',
  AmountFrom: 'amountFrom',
  AmountTo: 'amountTo',
} as const;

export const useTypeOptions = () => {
  const { t } = useTranslation();
  return useMemo(
    () =>
      TransferTypeValues.map((type) => ({
        label: t(`transfers:mappedTypes.${type}`),
        value: type,
      })),
    [t],
  );
};

export const useStatusOptions = () => {
  const { t } = useTranslation();
  return useMemo(
    () =>
      TransferStateValues.map((status) => ({
        label: t(`transfers:mappedStatuses.${status}`),
        value: status,
      })),
    [t],
  );
};

export const useSystemOptions = () => {
  const { t } = useTranslation();
  return useMemo(
    () =>
      TransferSystemValues.map((system) => ({
        label: t(`transfers:mappedSystems.${system}`),
        value: system,
      })),
    [t],
  );
};

export const useAccountOptions = (
  accounts: TAccountDto[] | undefined,
  currency: string,
) => {
  if (!accounts) return [];
  return accounts.reduce((accum, account) => {
    if (currency === '' || account.currency === currency) {
      accum.push({
        label: account.name,
        value: account.id,
      });
    }
    return accum;
  }, [] as TDropdownOption[]);
};

export const useCreateResolver = () => {
  const { t } = useTranslation();
  const { uiDateFormat } = useLanguageFeatures();
  return useCallback(() => {
    return yupResolver(
      yup.object().shape({
        createdFrom: createDateFieldResolver(t, {
          toFieldName: 'createdTo',
          maxDate: dayjs(),
          dateFormat: uiDateFormat,
        }),
        createdTo: createDateFieldResolver(t, {
          fromFieldName: 'createdFrom',
          maxDate: dayjs(),
          dateFormat: uiDateFormat,
        }),
        amountFrom: yup
          .number()
          .transform(nullifyNaN)
          .nullable()
          .min(0)
          .test((value, ctx) => {
            const maxAmount = ctx.parent.amountTo;

            if (!!value && !!maxAmount && value > maxAmount) {
              return ctx.createError({
                message: t('validate.maxMustBeGreaterMin'),
              });
            }
            return true;
          }),
        amountTo: yup.number().transform(nullifyNaN).min(0).nullable(),
      }),
    );
  }, [t, uiDateFormat]);
};
