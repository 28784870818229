import { ApiClientOffice, TToken } from '@payler/api/client-office';
import {
  TSpushOnboardingApplicantDto,
  TSpushOnboardingSumsubApplicantDto,
  TSumsubAccessTokenDto,
} from './types/onboarding';
import { ISpushApiClientOffice } from './types/api';
import { TAuth } from '@payler/auth';
import {
  TCustomerPaymentSessionDto,
  TConfirmPaymentParams,
} from './types/payment';

const PAYMENT_SESSION_API_URL = '/api/Merchant/frontoffice/payment-session';
const PAYMENT_TRANSFER_API_URL = '/api/Merchant/frontoffice/transfer';
const ONBOARDING_API_URL = '/api/onboarding-lite/frontoffice';

export class SpushApiClientOffice
  extends ApiClientOffice
  implements ISpushApiClientOffice
{
  public constructor(baseURL: string, auth?: TAuth<TToken>) {
    super(baseURL, auth);
  }

  /**
   * Запрос текущего кандидата Spush
   */
  async getSpushOnboardingApplicant(): Promise<TSpushOnboardingApplicantDto> {
    const resp = await this.axios.get(`${ONBOARDING_API_URL}/onboarding`);
    return resp.data;
  }

  /**
   * Запрос данных Sumsub текущего кандидата Spush
   */
  async getSpushOnboardingSumsubApplicant(): Promise<TSpushOnboardingSumsubApplicantDto> {
    const resp = await this.axios.get(`${ONBOARDING_API_URL}/sumsub`);
    return resp.data;
  }

  /**
   * Запрос токена доступа Sumsub текущего кандидата Spush
   */
  async getSpushOnboardingSumsubToken(): Promise<TSumsubAccessTokenDto> {
    const resp = await this.axios.get(`${ONBOARDING_API_URL}/sumsub/token`);
    return { token: resp.data };
  }

  /**
   * Запрос данных платежной сессии по ID
   */
  async getCustomerPaymentSession(
    sessionId: string,
  ): Promise<TCustomerPaymentSessionDto> {
    const resp = await this.axios.get(
      `${PAYMENT_SESSION_API_URL}/${sessionId}`,
    );
    return resp.data;
  }

  /**
   * Осуществление платежа
   */
  async confirmPayment(
    params: TConfirmPaymentParams,
  ): Promise<TCustomerPaymentSessionDto> {
    const resp = await this.axios.post(
      `${PAYMENT_SESSION_API_URL}/pay`,
      params,
    );
    return resp.data;
  }

  /**
   * Отмена платежа
   */
  async declinePayment(sessionId: string): Promise<void> {
    const resp = await this.axios.get(
      `${PAYMENT_SESSION_API_URL}/${sessionId}/cancel`,
    );
    return resp.data;
  }

  /**
   * Создание outgoing перевода c проверкой на возможность повторения платежа
   */
  override async createOutgoingTransfer(params: FormData): Promise<string> {
    const urlParams = new URLSearchParams(window.location.search);
    const transferOutgoingId = urlParams.get('transferOutgoingId');

    const response = await this.axios.get<{
      transferId: string;
      isRepeatable: boolean;
    }>(`${PAYMENT_TRANSFER_API_URL}/${transferOutgoingId}`);
    if (!response.data.isRepeatable) {
      throw new Error('Transaction cannot be repeated');
    }
    const transferId = await super.createOutgoingTransfer(params);

    return transferId;
  }
}
