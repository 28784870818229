import {
  TAccountDto,
  TRateRequestDto,
  TRiskLevelDto,
} from '@payler/api/client-office';
import { TExchangeDropdownOption } from '../../components/ExchangeDropdown/ExchangeDropdownOption';
import { getCurrencyIcon } from '../../currency';

export const getFilteredAccounts = (
  senderAccountCurrency: string | undefined,
  accounts: TAccountDto[] | undefined,
) => accounts?.filter((acc) => acc.currency !== senderAccountCurrency);

export const removeThousandSeparatorsAndSuffix = (value: string) =>
  value.replace(/\s/g, '').replace(/[^\d.-]/g, '');

export const getAccountOptions = (
  selectedAccountId: string | undefined,
  accounts: TAccountDto[] | undefined,
  formatNumber: (value: number | bigint) => string,
) => {
  return accounts?.map(
    (account) =>
      ({
        value: account.id,
        label: account.currency.toUpperCase(),
        icon: getCurrencyIcon(account.currency),
        caption: formatNumber(account.amount),
        isSelected: account.id === selectedAccountId,
      }) as TExchangeDropdownOption,
  );
};

export const getDefaultReceiverAccount = (
  senderAccountId: string | undefined,
  senderAccountCurrency: string | undefined,
  accounts: TAccountDto[] | undefined,
) =>
  accounts?.find(
    (acc) =>
      acc.id !== senderAccountId && senderAccountCurrency !== acc.currency,
  );

export const prepareRateRequestDto = (
  senderCurrency: string | undefined,
  receiverCurrency: string | undefined,
  amount: number | undefined,
  riskLevel: TRiskLevelDto,
): TRateRequestDto => {
  return {
    baseCurrency: senderCurrency?.toUpperCase(),
    quoteCurrency: receiverCurrency?.toUpperCase(),
    minAmount: amount,
    maxAmount: amount,
    tradeDirection: 'buy',
    riskLevel: riskLevel,
  };
};

export const calculateRateWithCommission = (
  rate: number,
  percentFee: number,
): number => {
  const commission = rate * percentFee;
  const rateWithCommission = Number((rate - commission).toFixed(4));
  return rateWithCommission;
};
