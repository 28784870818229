import {
  Box,
  VStack,
  Text,
  Button,
  HStack,
  Stack,
  Icon,
} from '@chakra-ui/react';
import { FilterIcon } from '@payler/ui-icons';
import { TextStyles } from '@payler/ui-theme';
import { useTranslation } from 'react-i18next';
import { FxTransferInfoDrawer } from '../../drawers/FxTransferInfoDrawer/FxTransferInfoDrawer';
import { IncomingTransferInfoDrawer } from '../../drawers/IncomingTransferInfoDrawer/IncomingTransferInfoDrawer';
import { OutgoingTransferInfoDrawer } from '../../drawers/OutgoingTransferInfoDrawer/OutgoingTransferInfoDrawer';
import { useTransferFiltersDrawer } from '../../drawers/TransferFiltersDrawer/transfer-filters-state';
import { TransferFiltersDrawer } from '../../drawers/TransferFiltersDrawer/TransferFiltersDrawer';
import { useSelectedTransfersFiltersCount } from '../../hooks/transfers/url';
import { useBankBreakpointValue } from '../../hooks/use-bank-breakpoint-value';
import { ContentLayout } from '../../layouts/ContentLayout/ContentLayout';
import { ActivitiesTable } from '../../tables/ActivitiesTable/ActivitiesTable';
import { useCallback } from 'react';
import { deleteTransferIdsFromUrl } from '../../hooks/transfers/helpers';
import { useSearchParams } from 'react-router-dom';

export const ActivityPage = () => {
  const isMobile = useBankBreakpointValue({ base: true, md: false });
  return (
    <ContentLayout>
      <Header />
      <VStack spacing={isMobile ? 2 : 3} alignItems="start">
        <Filters />
        <ActivitiesTable />
      </VStack>
      <TransferFiltersDrawer />
      <IncomingTransferInfoDrawer />
      <OutgoingTransferInfoDrawer />
      <FxTransferInfoDrawer />
    </ContentLayout>
  );
};

const Filters = () => {
  const [params, setParams] = useSearchParams();
  const { t } = useTranslation();
  const isMobile = useBankBreakpointValue({ base: true, md: false });
  const { open: openFilters } = useTransferFiltersDrawer();
  const filtersCount = useSelectedTransfersFiltersCount();
  const buttonAlign = isMobile ? 'stretch' : 'flex-start';
  const filtersBgColor =
    filtersCount === 0 ? undefined : isMobile ? 'brands.300' : 'primary.100';
  const iconColor = isMobile || filtersCount > 0 ? 'brands.500' : 'primary.350';

  const handleOpenFilters = useCallback(() => {
    deleteTransferIdsFromUrl(params, setParams);
    openFilters();
  }, [openFilters, params, setParams]);

  return (
    <Stack spacing={2} w="full" alignItems={buttonAlign}>
      <Button
        variant={isMobile ? 'outlined' : 'secondary'}
        size="small"
        onClick={handleOpenFilters}
        border={isMobile ? 'none' : undefined}
        bgColor={filtersBgColor}
        data-testid="button_open-filters"
      >
        <HStack spacing={1}>
          <Icon w={2} h={2} as={FilterIcon} color={iconColor} />
          <Text textStyle={TextStyles.Buttons16Medium}>
            {t('common:filters')}
          </Text>
        </HStack>
      </Button>
      {/* Скачивание отчета временно удалено в рамках BANK-1910 */}
    </Stack>
  );
};

const Header = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <Text as="h2" textStyle={TextStyles.h2} mb={2}>
        {t('menu:activity')}
      </Text>
    </Box>
  );
};
