import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  HStack,
  Text,
} from '@chakra-ui/react';
import { useHandleToastError } from '@payler/bank-utils';
import { TextStyles } from '@payler/ui-theme';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCancelOutgoingTransferMutation } from '../../hooks/transfers/queries';
import { useRecoilBoolean, useRecoilBooleanValue } from '@payler/bank-utils';
import { useTransferWizardContext } from './TransferWizard';

const KEY = 'alerts:ExitOtpAlert';

export const useShowExitOtpAlert = () => useRecoilBoolean(KEY).on;
export const useCloseExitOtpAlert = () => useRecoilBoolean(KEY).off;
const useIsOpenExitOtpAlert = () => useRecoilBooleanValue(KEY);

export const ExitOtpAlert = ({
  closeTransferModal,
}: {
  closeTransferModal: VoidFunction;
}) => {
  const { t } = useTranslation();
  const isOpen = useIsOpenExitOtpAlert();
  const close = useCloseExitOtpAlert();
  const handleToastError = useHandleToastError();
  const { mutate: cancelOutgoingTransfer } =
    useCancelOutgoingTransferMutation();

  const { transferId } = useTransferWizardContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onConfirmClick = useCallback(async () => {
    setIsLoading(true);
    if (transferId) {
      cancelOutgoingTransfer(transferId, {
        onError: (e) => handleToastError(e),
        onSettled: () => {
          setIsLoading(false);
          closeTransferModal();
          close();
        },
      });
    }
  }, [
    cancelOutgoingTransfer,
    close,
    closeTransferModal,
    handleToastError,
    transferId,
  ]);

  const cancelRef = React.useRef<HTMLButtonElement | null>(null);

  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={close}
      leastDestructiveRef={cancelRef}
    >
      <AlertDialogOverlay />
      <AlertDialogContent bgColor="white">
        <AlertDialogHeader pb={2}>
          <Text textStyle={TextStyles.h1}>
            {t('accounts:transferMoney.exitOtpAlertTitle')}
          </Text>
        </AlertDialogHeader>
        <AlertDialogBody
          textStyle={TextStyles.BodyText16Regular}
          color="primary.400"
        >
          {t('accounts:transferMoney.exitOtpAlertDescription')}
        </AlertDialogBody>
        <AlertDialogFooter>
          <HStack w="full">
            <Button
              w="inherit"
              variant="secondary"
              ref={cancelRef}
              onClick={close}
            >
              {t('common:cancel')}
            </Button>
            <Button
              w="inherit"
              onClick={onConfirmClick}
              variant="red"
              isLoading={isLoading}
            >
              {t('common:confirm')}
            </Button>
          </HStack>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
