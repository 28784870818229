import { Box, Grid, GridItem, GridProps } from '@chakra-ui/react';
import { FC, PropsWithChildren, useMemo } from 'react';
import { useLayoutContext } from '../../context/LayoutContextProvider';
import { useSideMenuPlaceholderOpened } from '../../hooks/use-opened-side-menu';

export const SIDE_MENU_WIDTH = 440;

export const ContentLayout: FC<PropsWithChildren> = ({ children }) => {
  const { isDesktop } = useLayoutContext();
  const isOpenedSideMenu = useSideMenuPlaceholderOpened();

  const gridProps: GridProps = useMemo(() => {
    if (isDesktop) {
      return {
        h: '100%',
        templateColumns: `1fr ${isOpenedSideMenu ? SIDE_MENU_WIDTH + 24 : 0}px`,
        transition: isOpenedSideMenu ? '0.3s linear' : '0.1s linear',
      };
    }
    return {};
  }, [isDesktop, isOpenedSideMenu]);

  return (
    <Grid {...gridProps}>
      <GridItem>
        <Box h="100%" maxW="600px" m="0px auto">
          {children}
        </Box>
      </GridItem>
      {isDesktop && <GridItem />}
    </Grid>
  );
};
