import axios, { AxiosHeaders, AxiosInstance } from 'axios';
import createLogger from 'debug';
import queryString from 'query-string';
import dayjs from 'dayjs';
import Utc from 'dayjs/plugin/utc';
import {
  TAccountDto,
  TAccountStatusDto,
  TAddAccountMutationParams,
  TAccountDraftedDto,
  TEditAccountNameMutationParams,
  TSendOtpCommand,
  TConfirmAccountCommand,
  TChangeAccountsOrderMutationParams,
  TBaasProviderCurrenciesDto,
} from './types/accounts';
import {
  TRecipientDto,
  TRecipientPagedDataDto,
  TCreateRecipientCommand,
  TRecipientsListQueryPagedParams,
} from './types/recipients';
import {
  TClientDto,
  TCreateFxTransferCommand,
  TCurrencyPairRateDto,
  TRateRequestDto,
  TTransferFeeParams,
  TTransferTypeParams,
  TTransfersFeeDto,
  TOutgoingTransferDto,
  TIncomingTransferDto,
  TTransferSystemDto,
  TFxTransferDto,
  TToken,
} from './types';
import {
  TTransferDtoPagedDataDto,
  TTransfersExportQueryParams,
  TTransfersQueryParams,
} from './types/transfers';
import {
  TOnboardingStageStepDto,
  TOnboardingStepDto,
  TPaymentResultDto,
  TStageTypeDto,
  TStepTypeDto,
} from './types/onboarding';
import { TCurrency, TCurrencyInfo, TPaginatedResult } from '@payler/bank-utils';
import { TReportsStatementsQueryParams } from './types/reports';
import { IApiClientOffice } from './types/api';
import {
  mockAccounts,
  mockFxTransfers,
  mockIncomingTransfers,
  mockOutgoingTransfers,
  mockTransfers,
} from './mocks';
import { mockRecipients } from './mocks/recipients';
import { TAuth } from '@payler/auth';

dayjs.extend(Utc);
const log = createLogger('api:bankDemo');

const paramsSerializer = (data: object): string => {
  return queryString.stringify(data, { arrayFormat: 'none' });
};

const getFakeDataById = <T extends { id: string }>(
  data: T[],
  id: string,
): Promise<T> => {
  const result = data.find((item) => item.id === id);
  return result ? Promise.resolve(result) : Promise.reject();
};

const getFakePagedData = <T>(
  data: T[],
  pageSize?: number,
): Promise<TPaginatedResult<T[]>> => {
  return Promise.resolve({
    data: data.slice(0, pageSize || 10),
    page: {
      pageNumber: 1,
      pageSize: pageSize || 10,
      totalPages: 1,
      totalRecords: data.length,
    },
  });
};

export class DemoApiClientOffice implements IApiClientOffice {
  private _axios: AxiosInstance;

  public get axios(): AxiosInstance {
    return this._axios;
  }

  constructor(
    private baseURL: string,
    private auth?: TAuth<TToken>,
  ) {
    log('DemoApiBank instance %O', { baseURL, token: auth?.accessToken });
    this._axios = axios.create({
      baseURL,
      paramsSerializer,
    });
    this.axios.interceptors.request.use(
      async function (config) {
        if (auth?.accessToken) {
          let token = auth.accessToken;
          try {
            const refreshed = await auth.updateToken(30);
            if (refreshed) {
              token = refreshed.access_token;
              log('token refreshed');
            }
            if (config.headers) {
              config.headers['Authorization'] = `Bearer ${token}`;
            } else {
              config.headers = new AxiosHeaders({
                Authorization: `Bearer ${token}`,
              });
            }
          } catch (e) {
            auth.logout();
          }
        } else {
          const token = localStorage.getItem('STORYBOOK_TOKEN');
          if (process.env['NODE_ENV'] !== 'production' && config.headers) {
            config.headers['Authorization'] = `Bearer ${token}`;
          } else {
            config.headers.set('Authorization', `Bearer ${token}`);
          }
        }
        return config;
      },
      function (error) {
        return Promise.reject(error);
      },
    );
  }

  /**
   * Получение списка всех счетов
   */
  getAccounts(params?: {
    Status?: TAccountStatusDto[];
    Currency?: TCurrency;
  }): Promise<TAccountDto[]> {
    return Promise.resolve(mockAccounts);
  }
  /**
   * Изменить порядок аккаунтов
   */
  async changeAccountsOrder(
    params: TChangeAccountsOrderMutationParams,
  ): Promise<void> {
    return Promise.reject(new Error());
  }

  /**
   * Получение счета по id
   */
  getAccount(id: string): Promise<TAccountDto> {
    return Promise.reject(new Error());
  }

  /**
   * Добавить новый счет
   */
  addAccount(params: TAddAccountMutationParams): Promise<TAccountDraftedDto> {
    return Promise.reject(new Error());
  }
  /**
   * Отправить OTP код при добавлении нового счета
   */
  async addAccountSendOTP(params: TSendOtpCommand): Promise<void> {
    return Promise.reject(new Error());
  }

  /**
   * Проверить OTP код при добавлении нового счета
   */
  addAccountOTPVerification(
    params: TConfirmAccountCommand,
  ): Promise<TAccountDto> {
    return Promise.reject(new Error());
  }

  /**
   * Изменить название счета
   */
  editAccountName({
    accountId,
    name,
  }: TEditAccountNameMutationParams): Promise<boolean> {
    return Promise.reject(new Error());
  }

  /**
   * Выписка по счету
   */
  getReportsStatements(params: TReportsStatementsQueryParams) {
    return Promise.reject(new Error());
  }

  /**
   * Получение данных текущего юзера
   */
  async getClient(): Promise<TClientDto> {
    const response = await this.axios.get('/api/onboarding/Client');
    return response.data;
  }

  /**
   * Получение списка всех реципиентов
   */
  getRecipients(
    params?: TRecipientsListQueryPagedParams,
  ): Promise<TRecipientPagedDataDto> {
    return getFakePagedData(mockRecipients, params?.PageSize);
  }

  /**
   * Получение реципиента по id
   */
  getRecipientById(id: string): Promise<TRecipientDto> {
    return Promise.reject(new Error());
  }

  /**
   * Добавление нового реципиента
   */
  addRecipient(data: TCreateRecipientCommand): Promise<string> {
    return Promise.reject(new Error());
  }
  /**
   * Сохранение реципиента
   */
  saveRecipient(recipientId: string): Promise<void> {
    return Promise.reject(new Error());
  }
  /**
   * Создание outgoing перевода
   */
  createOutgoingTransfer(params: FormData): Promise<string> {
    return Promise.reject(new Error());
  }

  /**
   * Получение outgoing трансфера по id
   */
  getOutgoingTransferById(transferId: string): Promise<TOutgoingTransferDto> {
    return getFakeDataById(mockOutgoingTransfers, transferId);
  }

  /**
   * Получение incoming трансфера по id
   */
  getIncomingTransferById(transferId: string): Promise<TIncomingTransferDto> {
    return getFakeDataById(mockIncomingTransfers, transferId);
  }

  /**
   * Получение fx трансфера по id
   */
  getFxTransferById(transferId: string): Promise<TFxTransferDto> {
    return getFakeDataById(mockFxTransfers, transferId);
  }

  /**
   * Подтверждение outgoing трансфера
   */
  confirmOutgoingTransfer(transferId: string, confirmationCode: string) {
    return Promise.reject(new Error());
  }
  /**
   * Отмена outgoing трансфера
   */
  cancelOutgoingTransfer(transferId: string) {
    return Promise.reject(new Error());
  }
  /**
   * Получение списка трансферов
   */
  getTransfers(
    params: TTransfersQueryParams,
  ): Promise<TTransferDtoPagedDataDto> {
    return getFakePagedData(mockTransfers, params.PageSize);
  }

  /** Получение ссылки экспорта */
  getIncomingTransfersExport(
    params: TTransfersExportQueryParams,
  ): Promise<void> {
    return Promise.reject(new Error());
  }

  /** Получение ссылки экспорта */
  getOutgoingTransfersExport(
    params: TTransfersExportQueryParams,
  ): Promise<void> {
    return Promise.reject(new Error());
  }

  /**
   * Старт онбординга
   */
  async startOnboarding(): Promise<void> {
    const resp = await this.axios.post('/api/onboarding/Onboarding');
    log('startOnboarding success');
    return resp.data;
  }

  /**
   * Запрос шага онбординга
   */
  async getOnboardingStep(step?: TStepTypeDto): Promise<TOnboardingStepDto> {
    const resp = await this.axios.get(
      `/api/onboarding/Onboarding/stages/companyApplication/steps/${step}`,
    );
    log('getOnboardingStep success, %o', step);
    return resp.data;
  }

  /**
   * Запрос информации о платеже для оплаты онбординга
   */
  async getOnboardingPayment(): Promise<TPaymentResultDto> {
    const resp = await this.axios.get('/api/onboarding/Payment');
    log('getOnboardingPayment success');
    return resp.data;
  }

  /**
   * Запрос словаря валют
   */
  async getCurrenciesInfo(): Promise<TCurrencyInfo[]> {
    const resp = await this.axios.get('/api/onboarding/Currencies');
    log('getCurrenciesInfo success');
    return resp.data;
  }

  async getOnboardingStages() {
    const resp = await this.axios.get(`/api/onboarding/Onboarding/stages`);
    log('getOnboardingStages success');
    return resp.data;
  }

  async getOnboardingStageStep(
    stage?: TStageTypeDto,
  ): Promise<TOnboardingStageStepDto> {
    const resp = await this.axios.get(
      `/api/onboarding/Onboarding/stages/${stage}`,
    );
    log('getOnboardingStageStep success');
    return resp.data;
  }

  /**
   * Запрос токена для zoho forms
   */
  async getToken(): Promise<string> {
    const resp = await this.axios.get('/api/onboarding/Tokens');
    log('getToken success');
    return resp.data.token;
  }

  /**
   * Генерация нового кода подтверждения
   */

  generateConfirmationCode(transferId: string | undefined): Promise<void> {
    return Promise.reject(new Error());
  }

  /**
   * Получение курса обмена
   */
  getCurrencyRate(data: TRateRequestDto): Promise<TCurrencyPairRateDto> {
    return Promise.reject(new Error());
  }

  /**
   * Создание перевода (обмена валюты)
   */
  createExchangeTransfer(data: TCreateFxTransferCommand): Promise<string> {
    return Promise.reject(new Error());
  }

  getTransferType(params: TTransferTypeParams): Promise<TTransferSystemDto> {
    return Promise.resolve('sepa');
  }

  getTransferFee(params: TTransferFeeParams): Promise<TTransfersFeeDto> {
    return Promise.resolve({ feeAmount: 50, tariffVersionId: '1' });
  }

  /**
   * Выписка account confirmation
   */
  getAccountConfirmation(accountId: string) {
    return Promise.reject(new Error());
  }

  /**
   * Выписка по трансферу
   */
  getTransferStatement(transferId: string) {
    return Promise.reject(new Error());
  }

  /**
   * Получение документа трансфера
   */
  getTransferDocument(fileName: string): Promise<void> {
    return Promise.reject(new Error());
  }

  async sendDeletionNotifications() {
    await this.axios.post('/api/onboarding/Client/deletion-notifications');
    log('sendDeletionNotifications success');
  }
  /**
   * Получение валюты аккаунта по account number/IBAN
   */
  getAccountCurrency(
    accountIdentifier: string,
  ): Promise<{ currencyCode: TCurrency }> {
    return Promise.reject(new Error());
  }

  /**
   * Получение поддерживаемого списка валют
   */
  async getBaasProviderCurrencies(): Promise<TBaasProviderCurrenciesDto> {
    return Promise.reject(new Error());
  }
}
