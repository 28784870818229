import { useApi } from '../../state/api';
import {
  TCreateFxTransferCommand,
  TCreateOutgoingTransferDto,
  TRateRequestDto,
  TTransferFeeParams,
  TTransfersQueryParams,
  TTransferTypeParams,
} from '@payler/api/client-office';
import { useCallback } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { PaginationProps } from '@payler/ui-components';
import { DEFAULT_PAGER_PROPS } from '../../const';
import { usePagerPropsFromUrl } from '../../helpers/use-pager-props-from-url';
import { usePagerUrlCallbacks } from '../pager/use-pager-url-callbacks';
import { useTransfersQueryParamsFromUrl } from './url';
import { useSearchParams } from 'react-router-dom';
import { deleteTransferIdsFromUrl } from './helpers';
import {
  createFormData,
  DEFAULT_SORT,
  useGetAxiosError,
} from '@payler/bank-utils';

const STALE_TIME = 60_000;
export const TRANSFER_OUTGOING_ID = 'transferOutgoingId';
export const TRANSFER_INCOMING_ID = 'transferIncomingId';
export const TRANSFER_FX_ID = 'transferFxId';

export const useOutgoingTransferQuery = (transferId: string) => {
  const api = useApi();
  return useQuery(
    ['transfer', 'outgoing', transferId],
    async () => await api.getOutgoingTransferById(transferId),
    {
      staleTime: STALE_TIME,
      suspense: true,
      enabled: !!transferId,
    },
  );
};

export const useIncomingTransferQuery = (transferId: string) => {
  const api = useApi();
  return useQuery(
    ['transfer', 'incoming', transferId],
    async () => await api.getIncomingTransferById(transferId),
    {
      staleTime: STALE_TIME,
      suspense: true,
      enabled: !!transferId,
    },
  );
};

export const useFxTransferQuery = (transferId: string) => {
  const api = useApi();
  return useQuery(
    ['transfer', 'fx', transferId],
    async () => await api.getFxTransferById(transferId),
    {
      staleTime: STALE_TIME,
      suspense: true,
      enabled: !!transferId,
    },
  );
};

/**
 * Подтверждение outgoing трансфера
 */
export const useOutgoingTransferConfirmMutation = () => {
  const client = useQueryClient();
  const api = useApi();
  return useMutation(
    ['transfer', 'outgoing', 'confirm'],
    (data: { transferId: string; confirmationCode: string }) =>
      api.confirmOutgoingTransfer(data.transferId, data.confirmationCode),
    {
      onSettled: () => client.invalidateQueries(['transfers', 'list']),
    },
  );
};

/**
 * Создание outgoing трансфера
 */
export const useCreateOutgoingTransferMutation = () => {
  const api = useApi();
  return useMutation(
    ['transfer', 'outgoing'],
    (transfer: TCreateOutgoingTransferDto) => {
      const formData = new FormData();
      createFormData(formData, transfer);

      return api.createOutgoingTransfer(formData);
    },
  );
};

export const useTransfersQuery = (
  params?: TTransfersQueryParams,
  enabled?: boolean,
) => {
  const api = useApi();
  const filters = useTransfersQueryParamsFromUrl();
  const pagerData = usePagerPropsFromUrl();
  const getError = useGetAxiosError();

  return useQuery(
    ['transfers', 'list', ...(params ? [params] : []), pagerData, filters],
    () =>
      api.getTransfers({
        ...DEFAULT_SORT,
        ...pagerData,
        ...filters,
        ...params,
      }),
    {
      staleTime: STALE_TIME,
      suspense: true,
      useErrorBoundary: (error) => !getError(error).fieldErrors,
      retry: (failureCount, error) =>
        !getError(error).fieldErrors && failureCount <= 3,
      enabled: enabled,
    },
  );
};

export const usePrefetchTransfersQuery = (totalPages?: number) => {
  const api = useApi();
  const filters = useTransfersQueryParamsFromUrl();
  const pagerData = usePagerPropsFromUrl();
  const queryClient = useQueryClient();
  const nextPage = pagerData.PageNumber + 1;
  if (totalPages && nextPage > totalPages) {
    return;
  }

  const prefetchPagerData = {
    ...pagerData,
    PageNumber: nextPage,
  };

  queryClient.prefetchQuery(
    ['transfers', 'list', prefetchPagerData, filters],
    () =>
      api.getTransfers({
        ...DEFAULT_SORT,
        ...prefetchPagerData,
        ...filters,
      }),
    { staleTime: STALE_TIME },
  );
};

export const useTransfersQueryPagerProps = () => {
  const { status, data } = useTransfersQuery();
  const { goToPage, setPageSize } = usePagerUrlCallbacks();
  if (status !== 'success') {
    return { ...DEFAULT_PAGER_PROPS, goToPage, setPageSize } as PaginationProps;
  }
  const { pageNumber, pageSize, totalRecords, totalPages } = data.page;
  return {
    goToPage,
    setPageSize,
    pageSize,
    currentPage: pageNumber,
    totalRecords,
    totalPages,
  } as PaginationProps;
};

export const useIncomingTransferIdFromUrl = () => {
  const [params] = useSearchParams();
  return params.get(TRANSFER_INCOMING_ID);
};

export const useOutgoingTransferIdFromUrl = () => {
  const [params] = useSearchParams();
  return params.get(TRANSFER_OUTGOING_ID);
};

export const useFxTransferIdFromUrl = () => {
  const [params] = useSearchParams();
  return params.get(TRANSFER_FX_ID);
};

export const useIncomingTransferByUrl = () => {
  const id = useIncomingTransferIdFromUrl();
  const { data } = useIncomingTransferQuery(id ?? '');
  return data;
};

export const useOutgoingTransferByUrl = () => {
  const id = useOutgoingTransferIdFromUrl();
  const { data } = useOutgoingTransferQuery(id ?? '');
  return data;
};

export const useFxTransferByUrl = () => {
  const id = useFxTransferIdFromUrl();
  const { data } = useFxTransferQuery(id ?? '');
  return data;
};

export const useSetIncomingTransferIdToUrl = () => {
  const [params, setParams] = useSearchParams();

  return useCallback(
    (transferIncomingId?: string) => {
      deleteTransferIdsFromUrl(params);
      transferIncomingId &&
        params.append(TRANSFER_INCOMING_ID, transferIncomingId);
      setParams(params);
    },
    [params, setParams],
  );
};

export const useSetOutgoingTransferIdToUrl = () => {
  const [params, setParams] = useSearchParams();

  return useCallback(
    (transferOutgoingId?: string) => {
      deleteTransferIdsFromUrl(params);
      transferOutgoingId &&
        params.append(TRANSFER_OUTGOING_ID, transferOutgoingId);
      setParams(params);
    },
    [params, setParams],
  );
};

export const useSetFxTransferIdToUrl = () => {
  const [params, setParams] = useSearchParams();

  return useCallback(
    (transferFxId?: string) => {
      deleteTransferIdsFromUrl(params);
      transferFxId && params.append(TRANSFER_FX_ID, transferFxId);
      setParams(params);
    },
    [params, setParams],
  );
};

export const useCreateExchangeMutation = () => {
  const api = useApi();
  const client = useQueryClient();
  return useMutation(
    ['transfer', 'fx'],
    (data: TCreateFxTransferCommand) => api.createExchangeTransfer(data),
    {
      onSettled: () => client.invalidateQueries(['transfers', 'list']),
    },
  );
};

export const useCurrencyRateQuery = (currencyRateParams: {
  rateRequestDto: TRateRequestDto;
  isRefetchData: boolean;
}) => {
  const api = useApi();
  const { rateRequestDto, isRefetchData } = currencyRateParams;
  return useQuery(
    [
      'transfer',
      'exchange',
      rateRequestDto.minAmount,
      rateRequestDto.baseCurrency,
      rateRequestDto.quoteCurrency,
      rateRequestDto.riskLevel,
    ],
    async () => await api.getCurrencyRate(rateRequestDto),
    {
      staleTime: STALE_TIME,
      refetchInterval: isRefetchData && 5000,
      enabled:
        !!rateRequestDto.baseCurrency &&
        !!rateRequestDto.quoteCurrency &&
        !!rateRequestDto.riskLevel &&
        rateRequestDto.baseCurrency !== rateRequestDto.quoteCurrency,
    },
  );
};

export const useTransferTypeQuery = (
  transferTypeParams: TTransferTypeParams,
) => {
  const api = useApi();
  return useQuery(
    [
      'transfer',
      'type',
      transferTypeParams.Currency,
      transferTypeParams.RecipientId,
    ],
    async () => await api.getTransferType(transferTypeParams),
    {
      staleTime: STALE_TIME,
      suspense: true,
      enabled: !!transferTypeParams.Currency,
    },
  );
};

export const useTransferFeeQuery = (params: TTransferFeeParams) => {
  const api = useApi();
  return useQuery(
    ['transfer', 'fee', params.Amount],
    async () => await api.getTransferFee(params),
    {
      staleTime: STALE_TIME,
      enabled: !!params.Amount,
    },
  );
};

export const useCancelOutgoingTransferMutation = () => {
  const api = useApi();
  const client = useQueryClient();
  return useMutation(
    ['transfer', 'outgoing', 'cancel'],
    (transferId: string) => api.cancelOutgoingTransfer(transferId),
    {
      onSuccess: () => client.invalidateQueries(['transfers', 'list']),
    },
  );
};
