import { Text } from '@chakra-ui/react';
import { TAccountDto } from '@payler/api/client-office';
import { OutlinedWarningSmallIcon } from '@payler/bank-utils';
import { TextStyles } from '@payler/ui-theme';
import { MayBe } from '@payler/utils';
import { FunctionComponent, SVGProps, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

type TAccountWarning = {
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  iconColor?: string;
  message: string | JSX.Element;
};

const getWarningsByAccount = (
  account: TAccountDto,
  t: TFunction<'translation', undefined>,
) => {
  const accountWarnings: TAccountWarning[] = [];

  if (account.state === 'restricted') {
    if (!account.allowIncomingOperations && !account.allowOutgoingOperations) {
      accountWarnings.push({
        icon: OutlinedWarningSmallIcon,
        iconColor: 'red.500',
        message: t('accounts:accountWarning.restrictedAll'),
      });
    } else if (
      account.allowIncomingOperations &&
      !account.allowOutgoingOperations
    ) {
      accountWarnings.push({
        icon: OutlinedWarningSmallIcon,
        iconColor: 'red.500',
        message: t('accounts:accountWarning.restrictedOutgoing'),
      });
    } else if (
      !account.allowIncomingOperations &&
      account.allowOutgoingOperations
    ) {
      accountWarnings.push({
        icon: OutlinedWarningSmallIcon,
        iconColor: 'red.500',
        message: t('accounts:accountWarning.restrictedIncoming'),
      });
    }
  }
  if ((account.paymentRequestsAmount ?? 0) !== 0) {
    accountWarnings.push({
      icon: OutlinedWarningSmallIcon,
      iconColor: 'red.500',
      message: t('accounts:accountWarning.paymentRequest', {
        amount: account.paymentRequestsAmount,
      }),
    });
  }
  if ((account.restrictedFundsAmount ?? 0) !== 0) {
    const translation = (
      <Trans
        i18nKey="accounts:accountWarning.incomingTransferPending"
        values={{
          amount: account.restrictedFundsAmount,
          currency: account.currency.toUpperCase(),
        }}
        components={{
          bold: <Text as="span" textStyle={TextStyles.Subtitle14Semibold} />,
        }}
      />
    );

    accountWarnings.push({
      icon: OutlinedWarningSmallIcon,
      iconColor: 'red.500',
      message: translation,
    });
  }

  return accountWarnings;
};

export const useAccountWarnings = (
  account: MayBe<TAccountDto>,
): TAccountWarning[] => {
  const { t } = useTranslation();
  if (!account) return [];

  return getWarningsByAccount(account, t);
};

export const useFirstAccountWarning = (account: MayBe<TAccountDto>) => {
  const warnings = useAccountWarnings(account);
  return warnings[0];
};

const useAccountListWarnings = (accounts: MayBe<TAccountDto[]>) => {
  const { t } = useTranslation();
  return useMemo(() => {
    const allWarnings: TAccountWarning[] = [];
    accounts?.forEach((account) => {
      allWarnings.push(...getWarningsByAccount(account, t));
    });
    return allWarnings;
  }, [accounts, t]);
};

export const useFirstAccountListWarning = (accounts: MayBe<TAccountDto[]>) => {
  return useAccountListWarnings(accounts)[0];
};
