import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  HStack,
  Icon,
  Link,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Logout20Icon } from '@payler/ui-icons';
import { TextStyles } from '@payler/ui-theme';

import {
  CompanyInfo,
  ProfileInfo,
} from '../../components/ProfileInfo/ProfileInfo';
import { LoaderBox } from '../../components/LoaderBox/LoaderBox';
import {
  CloseAccountModal,
  useCloseAccountModal,
} from '../../modals/CloseAccount/CloseAccount';
import { SupportIcon } from '../../icons';
import { useProfileInfo } from '../../hooks/use-profile-info';
import { ThemeSwitcher } from '../../components/Menu/ThemeSwitcher';
import { useClientQuery } from '../../hooks/clients/queries';
import { useClientOfficeConfig } from '../../config/ConfigProvider';
import { ContentLayout } from '../../layouts/ContentLayout/ContentLayout';
import { useConfigContext } from '../../context/config-context';
import { useAuth } from '@payler/auth';

export const ProfilePage = () => {
  const { isAuthLoading } = useAuth();

  if (isAuthLoading) return <LoaderBox />;

  return (
    <React.Suspense fallback={<LoaderBox variant="table" />}>
      <ContentLayout>
        <ProfileBody />
      </ContentLayout>
    </React.Suspense>
  );
};

const ProfileBody = () => {
  const { t } = useTranslation();
  const onOpenCloseAccountModal = useCloseAccountModal();
  const { environment } = useConfigContext();
  const profile = useProfileInfo();
  const { data } = useClientQuery();
  const { isDarkThemeAllowed } = useClientOfficeConfig();
  const { logout } = useAuth();
  const {
    legalInformationLink = 'https://payler.com/terms_and_conditions_for_business_accounts',
    supportLink = 'globalaccountsupport@payler.com',
  } = environment.properties || {};

  return (
    <VStack height="100%" alignItems="stretch" spacing={3}>
      <HStack
        spacing={2}
        width="100%"
        bg="secondary.500"
        p={2}
        borderRadius={1.5}
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <VStack spacing={0} alignItems="stretch">
          <Text textStyle={TextStyles.h2} color="primary.500">
            {profile.name}
          </Text>
          <Text textStyle={TextStyles.Subtitle14Regular} color="primary.400">
            {profile.preferred_username}
          </Text>
        </VStack>
        <HStack spacing={2}>
          <Button
            variant="secondary"
            onClick={logout}
            color="primary.400"
            data-testid="button_logout"
          >
            <Icon as={Logout20Icon} mr={1} />
            {t('menu:logout')}
          </Button>
          <Button
            as="a"
            variant="secondary"
            href={getSupportLink(supportLink, data?.legalName)}
            target="_blank"
            color="primary.400"
            data-testid="button_support"
          >
            <Icon as={SupportIcon} mr={1} />
            {t('profile:support')}
          </Button>
        </HStack>
      </HStack>
      <VStack
        h="full"
        spacing={3}
        alignItems="stretch"
        justifyContent="space-between"
      >
        <VStack spacing={3} alignItems="stretch">
          <ProfileInfo />
          <CompanyInfo />
        </VStack>
        <VStack spacing={3} alignItems="stretch">
          {isDarkThemeAllowed && (
            <Box
              bg="secondary.500"
              borderRadius={1.5}
              width="100%"
              px={3}
              py={2}
            >
              <ThemeSwitcher />
            </Box>
          )}
          <HStack spacing={3} justifyContent="center">
            <Link
              isExternal
              href={legalInformationLink}
              textStyle={TextStyles.Subtitle14Medium}
              color="primary.350"
              data-testid="link_legal-information"
            >
              {t('profile:legalInformation')}
            </Link>
            <Link
              as="button"
              size="xs"
              onClick={() => onOpenCloseAccountModal('step1')}
              color="primary.350"
              textStyle={TextStyles.Subtitle14Medium}
              data-testid="link_close-account"
            >
              {t('profile:closeAccount.closeAccount')}
            </Link>
          </HStack>
        </VStack>
      </VStack>
      <CloseAccountModal />
    </VStack>
  );
};

const getSupportLink = (supportLink: string, legalName: string = '') =>
  `mailto:${supportLink}?subject=${encodeURIComponent(
    `${legalName}: business account problem`,
  )}`;
