import {
  TAddAccountMutationParams,
  TChangeAccountsOrderMutationParams,
  TConfirmAccountCommand,
  TEditAccountNameMutationParams,
  TSendOtpCommand,
} from '@payler/api/client-office';
import { useMutation } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useApi } from '../../state/api';
import { useDropAccountsCache } from './cache';
import { useHandleToastError } from '@payler/bank-utils';

/** Добавить счёт */
export const useAddAccountMutation = () => {
  const api = useApi();
  return useMutation(
    ['addAccount'],
    async (data: TAddAccountMutationParams) => await api.addAccount(data),
  );
};

/** Запросить новый ОТП код*/
export const useAddAccountResendOTPMutation = () => {
  const api = useApi();
  return useMutation(
    ['addAccountSendOTP'],
    async (data: TSendOtpCommand) => await api.addAccountSendOTP(data),
  );
};

/** Подтвердить OTP код*/
export const useAddAccountConfirmOTPMutation = () => {
  const api = useApi();
  const dropCache = useDropAccountsCache();

  return useMutation(
    ['addAccountConfirmOTP'],
    async (data: TConfirmAccountCommand) => {
      await api.addAccountOTPVerification(data);
    },
    {
      onSuccess: () => {
        dropCache();
      },
    },
  );
};

/**
 * Изменить название счета (так же сбрасывается кэш счетов)
 */
export const useEditAccountNameMutation = () => {
  const api = useApi();
  const dropCache = useDropAccountsCache();

  const onSuccess = useCallback(() => {
    dropCache();
  }, [dropCache]);

  return useMutation(
    ['editAccountName'],
    (data: TEditAccountNameMutationParams) => api.editAccountName(data),
    {
      onSuccess,
    },
  );
};

/** Изменить порядок счетов */
export const useChangeAccountsOrderMutation = () => {
  const api = useApi();
  const dropCache = useDropAccountsCache();
  const handleToastError = useHandleToastError();

  return useMutation(
    ['changeAccountsOrder'],
    async (data: TChangeAccountsOrderMutationParams) =>
      await api.changeAccountsOrder(data),
    {
      onSuccess: () => {
        dropCache();
      },
      onError: (e) => {
        handleToastError(e);
        dropCache();
      },
    },
  );
};
