import { useQuery } from '@tanstack/react-query';
import { useApi } from '../../state/api';

const CURRENCIES_STALE_TIME = 60 * 1000;

export const useBaasProviderCurrenciesQuery = () => {
  const api = useApi();
  return useQuery(
    ['BaasProviderCurrencies'],
    async () => await api.getBaasProviderCurrencies(),
    {
      staleTime: CURRENCIES_STALE_TIME,
      suspense: true,
    },
  );
};
