import { common } from './namespaces/common';
import { menu } from './namespaces/menu';
import { onboarding } from './namespaces/onboarding';
import { currencies } from './namespaces/currencies';
import { fields } from './namespaces/fields';
import { titles } from './namespaces/titles';
import { profile } from './namespaces/profile';
import { accounts } from './namespaces/accounts';
import { recipients } from './namespaces/recipients';
import { transfers } from './namespaces/transfers';
import { demo } from './namespaces/demo';

const translationEn = {
  common,
  menu,
  onboarding,
  currencies,
  fields,
  titles,
  profile,
  accounts,
  recipients,
  transfers,
  demo,
} as const;

export default translationEn;

export type TBankTranslation = typeof translationEn;
