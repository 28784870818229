import {
  CardContent,
  ErrorBoundary,
  NoData,
  ScrollBarWithFade,
} from '@payler/ui-components';
import {
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { tableStyleConfig } from '../tableStyleConfig';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { TableSkeleton } from '../TableSkeleton';
import { TextStyles } from '@payler/ui-theme';
import { TableFooter } from '../TableFooter';
import {
  usePrefetchTransfersQuery,
  useSetFxTransferIdToUrl,
  useSetIncomingTransferIdToUrl,
  useSetOutgoingTransferIdToUrl,
  useTransfersQuery,
  useTransfersQueryPagerProps,
} from '../../hooks/transfers/queries';
import { LoaderBox } from '../../components/LoaderBox/LoaderBox';
import { TTransferDto, TTransfersQueryParams } from '@payler/api/client-office';
import { useLanguageFeatures } from '../../hooks/use-language-features';
import { useTranslation } from 'react-i18next';
import { useCloseAllAccountsDrawer } from '../../components/AllAccountsDrawer/AllAccountsDrawer';
import { useTransferFiltersDrawer } from '../../drawers/TransferFiltersDrawer/transfer-filters-state';
import { useSelectedTransfersFiltersCount } from '../../hooks/transfers/url';
import { TransferIconWithStatus } from '../../components/TransferIconWithStatus/TransferIconWithStatus';

export const ActivitiesTable = () => {
  return (
    <React.Suspense fallback={<LoaderBox variant="table" />}>
      <CardContent
        p={0}
        bg="secondary.500"
        borderRadius={1.5}
        border="0.5px solid"
        borderColor="primary.100"
        overflow="hidden"
        width="100%"
      >
        <Transfers />
      </CardContent>
    </React.Suspense>
  );
};

const Transfers = () => {
  const paginationProps = useTransfersQueryPagerProps();
  usePrefetchTransfersQuery(paginationProps.totalPages);
  return (
    <>
      <React.Suspense fallback={<LoaderBox />}>
        <TransfersTableWithData />
      </React.Suspense>
      <ErrorBoundary>
        <React.Suspense fallback={null}>
          <TableFooter paginationProps={paginationProps} />
        </React.Suspense>
      </ErrorBoundary>
    </>
  );
};

export const TransfersTableWithData: FC<{
  params?: TTransfersQueryParams;
  enabled?: boolean;
}> = ({ params, enabled }) => {
  const { status, data, error } = useTransfersQuery(params, enabled);
  const { setError, open } = useTransferFiltersDrawer();
  const filtersCount = useSelectedTransfersFiltersCount();

  useEffect(() => {
    setError(error);
    if (error && filtersCount > 0) {
      open();
    }
  }, [error, filtersCount, open, setError]);

  switch (status) {
    case 'loading':
      return <TableSkeleton />;
    case 'error':
      return <NoData />;
    case 'success':
    default: {
      const empty = data.data.length === 0;
      if (empty) {
        return <NoData />;
      } else {
        return (
          <ScrollBarWithFade>
            <TableContainer position="relative">
              <Table styleConfig={tableStyleConfig} bg="secondary.500">
                <Tbody>
                  {data.data.map((row) => (
                    <Row data={row} key={row.id} />
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </ScrollBarWithFade>
        );
      }
    }
  }
};

const Row = ({ data }: { data: TTransferDto }) => {
  const { t } = useTranslation();
  const { serverDatetimeToDisplay } = useLanguageFeatures();
  const transferEntity = getTransferEntity(data);
  const { formatAmountByCurrency } = useLanguageFeatures();
  const setIncomingTransferId = useSetIncomingTransferIdToUrl();
  const setOutgoingTransferId = useSetOutgoingTransferIdToUrl();
  const setFxTransferId = useSetFxTransferIdToUrl();
  const closeAllAccountsDrawer = useCloseAllAccountsDrawer();
  const { close: closeFilters } = useTransferFiltersDrawer();
  const handleClick = useCallback(() => {
    closeAllAccountsDrawer();
    closeFilters();
    switch (transferEntity.transferType) {
      case 'incoming':
        setIncomingTransferId(transferEntity.transfer?.id);
        break;
      case 'outgoing':
        setOutgoingTransferId(transferEntity.transfer?.id);
        break;
      default:
        setFxTransferId(transferEntity.transfer?.id);
        break;
    }
  }, [
    closeAllAccountsDrawer,
    closeFilters,
    transferEntity.transferType,
    transferEntity.transfer?.id,
    setIncomingTransferId,
    setOutgoingTransferId,
    setFxTransferId,
  ]);

  const isSelfTransfer = transferEntity.transfer?.operationType === 'self';

  const rowTitle = useMemo(() => {
    if (isSelfTransfer) {
      return t('transfers:transferToSelf');
    }
    switch (transferEntity.transferType) {
      case 'incoming':
        return transferEntity.transfer?.sender.legalName;
      case 'outgoing':
        return transferEntity.transfer?.recipient.legalName;
      case 'fx':
        return t('accounts:exchange.selectStep.exchangeDirection', {
          senderCurrency:
            transferEntity.transfer?.recipientSide.currency.toUpperCase(),
          receiverCurrency:
            transferEntity.transfer?.recipientSide.currency.toUpperCase(),
        });
      default:
        return;
    }
  }, [transferEntity.transfer, t, transferEntity.transferType, isSelfTransfer]);

  const transferAmount = useMemo(() => {
    const { transfer, transferType } = transferEntity;
    if (!transfer) return '';
    let amount: number;
    let currency: string;
    let sign: string;
    switch (transferType) {
      case 'incoming':
        amount = transfer.recipientSide.totalAmount || 0;
        currency = transfer.recipientSide.currency;
        sign = '+';
        break;
      case 'outgoing':
        amount = transfer.senderSide.totalAmount || 0;
        currency = transfer.senderSide.currency;
        sign = '-';
        break;
      case 'fx':
        amount = transfer.recipientSide.amount || 0;
        currency = transfer.recipientSide.currency;
        sign = '+';
        break;
    }
    return `${sign} ${formatAmountByCurrency(amount, currency)}`;
  }, [formatAmountByCurrency, transferEntity]);

  return (
    <Tr onClick={handleClick}>
      <Td>
        <Flex as="span" alignItems="center">
          <TransferIconWithStatus
            status={transferEntity.transfer?.state}
            type={transferEntity.transferType}
            isSelfTransfer={isSelfTransfer}
          />
          <VStack
            as="span"
            spacing="2px"
            alignItems="baseline"
            flexDirection="column"
            ml={2}
          >
            <Text
              textStyle={TextStyles.tables}
              color="primary.500"
              maxW={{base: '400px', xs: '110px', xssm: '160px' }}
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {rowTitle}
            </Text>
            <Text textStyle={TextStyles.Caption12Regular} color="primary.350">
              {serverDatetimeToDisplay(data.created, 'datetime')}
            </Text>
          </VStack>
        </Flex>
      </Td>
      <Td>
        <VStack
          as="span"
          spacing="2px"
          alignItems="flex-end"
          flexDirection="column"
        >
          <Text as="span" color="primary.500" textStyle={TextStyles.tables}>
            {transferAmount}
          </Text>
          {transferEntity.transfer && transferEntity.transferType === 'fx' && (
            <Text color="primary.350" textStyle={TextStyles.Caption12Regular}>
              {`- ${formatAmountByCurrency(
                transferEntity.transfer?.senderSide.amount || 0,
                transferEntity.transfer?.senderSide.currency,
              )}`}
            </Text>
          )}
        </VStack>
      </Td>
    </Tr>
  );
};

interface TFxTransfer {
  transferType: 'fx';
  transfer: TTransferDto['fx'];
  entityId: string;
}

interface TOutgoingTransfer {
  transferType: 'outgoing';
  transfer: TTransferDto['outgoing'];
  entityId: string;
}

interface TIncomingTransfer {
  transferType: 'incoming';
  transfer: TTransferDto['incoming'];
  entityId: string;
}

type TTransferEntity = TFxTransfer | TOutgoingTransfer | TIncomingTransfer;

const getTransferEntity = (data: TTransferDto): TTransferEntity => {
  const transferType = data.incoming
    ? 'incoming'
    : data.outgoing
      ? 'outgoing'
      : 'fx';

  switch (transferType) {
    case 'incoming':
      return {
        transferType,
        transfer: data.incoming,
        entityId: data.id,
      };
    case 'outgoing':
      return {
        transferType,
        transfer: data.outgoing,
        entityId: data.id,
      };
    default:
      return {
        transferType,
        transfer: data.fx,
        entityId: data.id,
      };
  }
};
