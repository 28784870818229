import { TAccountDto } from '@payler/api/client-office';
import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useAccountsQuery } from '../hooks/accounts/queries';

type TAccountsReorderContextValue = {
  isReorderMode: boolean;
  setIsReorderMode: Dispatch<SetStateAction<boolean>>;
  reorderedAccounts: TAccountDto[] | undefined;
  setReorderedAccounts: Dispatch<SetStateAction<TAccountDto[] | undefined>>;
};

const AccountsReorderContext = createContext<TAccountsReorderContextValue>({
  isReorderMode: false,
  reorderedAccounts: undefined,
} as TAccountsReorderContextValue);

export const AccountsReorderContextProvider: FCC = (props) => {
  const { data } = useAccountsQuery();
  const [isReorderMode, setIsReorderMode] = useState(false);
  const [reorderedAccounts, setReorderedAccounts] = useState(data);

  // При изменении серверных данных, приводим порядок к серверному
  useEffect(() => {
    setReorderedAccounts(data);
  }, [data]);

  const ctx = useMemo(
    () => ({
      isReorderMode,
      setIsReorderMode,
      reorderedAccounts,
      setReorderedAccounts,
    }),
    [isReorderMode, reorderedAccounts],
  );

  return <AccountsReorderContext.Provider {...props} value={ctx} />;
};

export const useAccountsReorderContext = () =>
  useContext(AccountsReorderContext);
