import { FC, PropsWithChildren, ReactNode, useMemo } from 'react';
import {
  Button,
  chakra,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalBodyProps,
  ModalContent,
  ModalContentProps,
  ModalFooter,
  ModalFooterProps,
  ModalHeader,
  ModalHeaderProps,
  ModalOverlay,
  ModalProps,
  useMediaQuery,
  useModalContext,
  VStack,
} from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import { useBankBreakpointValue } from '../../hooks/use-bank-breakpoint-value';
import { BackArrowIcon } from '@payler/ui-icons';
import { useTranslation } from 'react-i18next';
import { useLayoutContext } from '../../context/LayoutContextProvider';
import { CloseButton } from '../CloseButton/CloseButton';

export type BankModalProps = PropsWithChildren<{
  close: VoidFunction;
  open: boolean;
  finalFocusRef?: ModalProps['finalFocusRef'];
  scrollBehavior?: 'inside' | 'outside';
  isCentered?: boolean;
}>;

const lowHeightProps = (scrollBehavior: string): ModalContentProps => {
  return {
    my: 2,
    maxH: scrollBehavior === 'inside' ? 'calc(100vh - 32px)' : undefined,
  };
};

export const BankModal: FC<BankModalProps> = ({
  close,
  open,
  children,
  finalFocusRef,
  scrollBehavior = 'inside',
  isCentered = false,
}) => {
  const paddings = useBankBreakpointValue({
    base: { pt: 2, pb: 3 },
    sm: { pt: 4, pb: 4 },
  });

  const [lowHeight, forceMobile] = useMediaQuery([
    '(max-height: 1000px)',
    '(max-height: 500px)',
  ]);

  const isMobile =
    useBankBreakpointValue({ base: true, sm: false }) || forceMobile;
  const modalSizeBase = useBankBreakpointValue({ base: 'full', sm: 'md' });
  const modalSize = isMobile ? 'full' : modalSizeBase;

  const contentProps = useMemo(() => {
    if (isMobile) return undefined;
    return lowHeight ? lowHeightProps(scrollBehavior) : undefined;
  }, [isMobile, lowHeight, scrollBehavior]);

  return (
    <Modal
      isOpen={open}
      onClose={close}
      size={modalSize}
      finalFocusRef={finalFocusRef}
      scrollBehavior={scrollBehavior}
      isCentered={isCentered}
    >
      <ModalOverlay />
      <ModalContent
        px={0}
        pt={paddings?.pt}
        pb={paddings?.pb}
        bg="secondary.500"
        flex="1"
        {...contentProps}
      >
        {children}
      </ModalContent>
    </Modal>
  );
};

export const BankModalTitle: FC<{
  title?: string;
  description?: ReactNode | ReactNode[];
}> = ({ title, description }) => {
  const padding = useBankBreakpointValue({ base: 2, sm: 4 });

  return (
    <VStack px={padding} py={0} mb={2} spacing={1} alignItems="stretch">
      <chakra.h1 textStyle={TextStyles.h1}>{title}</chakra.h1>
      {description &&
        (!Array.isArray(description) ? (
          <chakra.p
            textStyle={TextStyles.Subtitle14Regular}
            color="primary.400"
          >
            {description}
          </chakra.p>
        ) : (
          description.map((descriptionItem, i) => (
            <chakra.p
              key={i}
              textStyle={TextStyles.Subtitle14Regular}
              color="primary.400"
            >
              {descriptionItem}
            </chakra.p>
          ))
        ))}
    </VStack>
  );
};

type ModalHeaderActionsProps = ModalHeaderProps & {
  isShowBack?: boolean;
  isDisableBack?: boolean;
  onBack?: VoidFunction;
  onModalClose?: VoidFunction;
};

export const ModalHeaderActions: FC<ModalHeaderActionsProps> = ({
  isShowBack = false,
  isDisableBack = false,
  onBack,
  onModalClose,
  ...rest
}) => {
  const px = useBankBreakpointValue({ base: 2, sm: 4 });
  const { onClose } = useModalContext();
  const { t } = useTranslation();
  const { isMobile } = useLayoutContext();
  return (
    <ModalHeader
      py={0}
      px={px}
      mb={2}
      as={HStack}
      justifyContent={isShowBack ? 'space-between' : 'start'}
      {...rest}
    >
      {isShowBack && (
        <Button
          variant="outlined"
          border="none"
          size={'small'}
          onClick={onBack}
          isDisabled={isDisableBack}
          data-testid="button_back"
        >
          <Icon mr={isMobile ? 0 : 0.5} w={2} h={2} as={BackArrowIcon} />
          {!isMobile && t('common:back')}
        </Button>
      )}
      <CloseButton onClick={onModalClose ?? onClose} />
    </ModalHeader>
  );
};

export const BankModalBody: FC<PropsWithChildren<ModalBodyProps>> = ({
  children,
  ...rest
}) => {
  const px = useBankBreakpointValue({ base: 2, sm: 4 });
  return (
    <ModalBody py={0} px={px} {...rest}>
      {children}
    </ModalBody>
  );
};

export const BankModalFooter: FC<PropsWithChildren<ModalFooterProps>> = ({
  children,
  ...rest
}) => {
  const px = useBankBreakpointValue({ base: 2, sm: 4 });
  return (
    <ModalFooter mt={2} pt={1} pb={0} px={px} justifyContent="start" {...rest}>
      {children}
    </ModalFooter>
  );
};
