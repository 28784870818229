import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import CustomParseFormat from 'dayjs/plugin/customParseFormat';
import IsToday from 'dayjs/plugin/isToday';
import IsYesterday from 'dayjs/plugin/isYesterday';
import { MayBe } from '@payler/bank-utils';
dayjs.extend(LocalizedFormat);
dayjs.extend(CustomParseFormat);
dayjs.extend(IsToday);
dayjs.extend(IsYesterday);

export type TConvertDateToDate = (value: MayBe<string>) => string | null;

export type TConvertDateToDatetime = (
  value: MayBe<string>,
  edgeOfDay?: 'start' | 'end' | undefined,
) => string | undefined;

export type TFormatBankDate = (
  value: MayBe<dayjs.Dayjs>,
  returnFormat: 'ui' | 'server' | 'display',
  returnType: 'date' | 'time' | 'datetime',
) => string | null;

export type TParseBankDate = (
  value: MayBe<string>,
  inputFormat: 'ui' | 'server',
  inputType: 'date' | 'time' | 'datetime',
) => dayjs.Dayjs | null;

const DATE_FORMATS = {
  ui: {
    datetime: 'DD.MM.YYYY HH:mm',
    date: 'DD.MM.YYYY',
    time: 'HH:mm',
  },
  server: {
    datetime: 'YYYY-MM-DDTHH:mm:ss.SSSZ',
    date: 'YYYY-MM-DD',
    time: 'HH:mm:ss',
  },
};

export const useLanguageFeatures = () => {
  const {
    i18n: { language },
    t,
  } = useTranslation();

  return useMemo(() => {
    const numFormatter = new Intl.NumberFormat(language);
    const numShortFormatter = new Intl.NumberFormat(language, {
      notation: 'compact',
    });
    const regionNames = new Intl.DisplayNames([language], { type: 'region' });
    const formatNumber = numFormatter.format;
    const formatNumberShort = numShortFormatter.format;

    /**
     * Отформатировать dayjs значение в нужный date/datetime/time формат
     * @param value dayjs значение
     * @param returnFormat выходной формат. 'display' - это формат для отображения в ui где используются today/yesterday
     * @param returnType какую часть даты форматировать
     * @returns отформатированная строка
     */
    const formatBankDate: TFormatBankDate = (
      value: MayBe<dayjs.Dayjs>,
      returnFormat: 'ui' | 'server' | 'display',
      returnType: 'date' | 'time' | 'datetime',
    ) => {
      if (!value) return null;
      if (returnFormat === 'display') {
        let displayFormat = DATE_FORMATS.ui[returnType];
        if (value.isToday()) {
          displayFormat = displayFormat.replace(
            DATE_FORMATS.ui.date,
            `[${t('common:dates.today')}]`,
          );
        } else if (value.isYesterday()) {
          displayFormat = displayFormat.replace(
            DATE_FORMATS.ui.date,
            `[${t('common:dates.yesterday')}]`,
          );
        }
        return value.format(displayFormat);
      }
      return value.format(DATE_FORMATS[returnFormat][returnType]);
    };

    /**
     * Преобразовать строковую date/datetime/time в dayjs
     * @param value строковое значение
     * @param inputFormat в каком формате передана строка
     * @param inputType какая часть даты передана
     * @returns dayjs дата
     */
    const parseBankDate: TParseBankDate = (
      value: MayBe<string>,
      inputFormat: 'ui' | 'server',
      inputType: 'date' | 'time' | 'datetime',
    ) => {
      if (!value) return null;
      if (inputFormat === 'server' && inputType === 'datetime') {
        // для парсинга datetime SERVER_DATETIME_FORMAT не всегда подходит (иногда сервер присылает без миллисекунд, и dayjs уже не может это прочитать)
        return dayjs(value);
      }
      return dayjs(value, DATE_FORMATS[inputFormat][inputType]);
    };

    /**
     * Преобразовать строковую дату ui-date в строковую дату server-date.
     * Используется для преобразования formValue в urlValue
     * @param value строковое значение в ui-date формате
     * @returns строковое значение в server-date формате
     */
    const uiDateToServerDate: TConvertDateToDate = (value: MayBe<string>) => {
      return formatBankDate(
        parseBankDate(value, 'ui', 'date'),
        'server',
        'date',
      );
    };

    /**
     * Преобразовать строковую дату sever-date в строковую дату ui-date.
     * Используется для преобразования urlValue в formValue
     * @param value строковое значение в server-date формате
     * @returns строковое значение в ui-date формате
     */
    const serverDateToUiDate: TConvertDateToDate = (value: MayBe<string>) => {
      return formatBankDate(
        parseBankDate(value, 'server', 'date'),
        'ui',
        'date',
      );
    };

    /**
     * Преобразовать строковую дату sever-datetime в строковую дату Display указанного типа. Использовать только для отображения данных а не для работы в коде
     * @param value строковая дата sever-datetime
     * @param returnType какую часть даты форматировать
     * @returns строковое представление для Display
     */
    const serverDatetimeToDisplay = (
      value: MayBe<string>,
      returnType: 'date' | 'time' | 'datetime',
    ) => {
      return formatBankDate(
        parseBankDate(value, 'server', 'datetime'),
        'display',
        returnType,
      );
    };

    /**
     * Преобразовать строковую дату sever-date в строковую дату server-datetime с возможностью преобразования в начало/конец дня.
     * Используется когда в urlValue находится date, но сервер ожидает datetime. Локальная дата будет преобразована в utc(true)
     * @param value - строковое значение в server-date формате
     * @param edgeOfDay - преобразовать в начало или конец дня
     * @returns строковое значение в server-datetime формате
     */
    const serverDateToServerDatetime: TConvertDateToDatetime = (
      value: MayBe<string>,
      edgeOfDay?: 'start' | 'end',
    ) => {
      let dayJsValue = parseBankDate(value, 'server', 'date')?.utc(true);
      if (dayJsValue && edgeOfDay) {
        switch (edgeOfDay) {
          case 'start':
            dayJsValue = dayJsValue.startOf('day');
            break;
          case 'end':
            dayJsValue = dayJsValue.endOf('day');
            break;
        }
      }
      return formatBankDate(dayJsValue, 'server', 'datetime') ?? undefined;
    };

    const formatAmountByCurrency = (amount: number, currency: string) => {
      const formattedAmount = new Intl.NumberFormat(language, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(amount);
      return `${formattedAmount}${
        currency ? ' ' + currency.toUpperCase() : ''
      }`;
    };

    return {
      formatBankDate,
      parseBankDate,
      uiDateToServerDate,
      serverDateToUiDate,
      serverDateToServerDatetime,
      serverDatetimeToDisplay,
      uiDatetimeFormat: DATE_FORMATS.ui.datetime,
      uiDateFormat: DATE_FORMATS.ui.date,
      uiTimeFormat: DATE_FORMATS.ui.time,
      language,
      formatNumber,
      formatNumberShort,
      formatAmountByCurrency,
      regionNames,
    };
  }, [language, t]);
};
