import React, { useState } from 'react';
import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Text,
  VStack,
} from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import { useTranslation } from 'react-i18next';
import { ArrowUp14Icon, ExchangeIcon, PlusCircleIcon } from '@payler/ui-icons';
import { getCurrencyIcon } from '../../currency';
import { useLanguageFeatures } from '../../hooks/use-language-features';
import { useAccountByUrl } from '../../hooks/accounts/queries';
import { EllipsisIcon } from '../../icons';
import { useBankBreakpointValue } from '../../hooks/use-bank-breakpoint-value';
import { ActionsPopover } from '../ActionsPopover/ActionsPopover';
import { MobileMoreDrawer } from '../MobileMoreDrawer/MobileMoreDrawer';
import { useShowTransferModal } from '../../modals/TransferModal/TransferModal';
import { useShowAddMoneyModal } from '../../modals/AddMoney/AddMoney';
import { usePermissions } from '../../hooks/use-permissions';
import { useShowExchangeModal } from '../../modals/Exchange/ExchangeModal';
import { useCheckAppFlagEnabled } from '../../config/ConfigProvider';
import { useIsActiveClient } from '../../hooks/clients/helpers';

export const AccountInfo: React.FC = () => {
  const account = useAccountByUrl();
  const isActiveClient = useIsActiveClient();
  const { formatAmountByCurrency } = useLanguageFeatures();
  const onOpenTransferModal = useShowTransferModal();
  const onOpenAddMoneyModal = useShowAddMoneyModal();
  const onOpenExchangeModal = useShowExchangeModal();
  const isFeautureExchangeEnabled = useCheckAppFlagEnabled('accounts.exchange');
  const { t } = useTranslation();

  const isDesktop = useBankBreakpointValue({ base: false, md: true });
  const [isMobileMoreShown, setIsMobileMoreShown] = useState<boolean>(false);
  const { isGlobalAccounts } = usePermissions();

  if (!account) return null;

  return (
    <Box
      borderRadius={1.5}
      boxShadow="accountInfo"
      p={2}
      bg="secondary.500"
      w="inherit"
    >
      <VStack spacing={2} alignItems="baseline">
        <HStack spacing={2}>
          <Flex alignItems="center">
            <Icon w="40px" h="40px" as={getCurrencyIcon(account.currency)} />
          </Flex>
          <VStack spacing={0} alignItems="baseline">
            <Text textStyle={TextStyles.h2} color="primary.500">
              {formatAmountByCurrency(account.amount, account.currency)}
            </Text>
            <Text textStyle={TextStyles.Subtitle14Regular} color="primary.400">
              {account.name}
            </Text>
          </VStack>
        </HStack>
        <Flex width="100%" justifyContent="space-between" alignItems="center">
          <HStack spacing={1.5}>
            <Button
              variant="primary"
              size="sm"
              onClick={onOpenTransferModal}
              isDisabled={
                (!isActiveClient || !account.allowOutgoingOperations) &&
                isGlobalAccounts
              }
              data-testid="button_open-send-money"
            >
              <Icon as={ArrowUp14Icon} mr={0.5} color="secondary.500" />
              {t('accounts:accountInfo.sendMoney')}
            </Button>
            <Button
              variant="primary"
              size="sm"
              onClick={onOpenAddMoneyModal}
              isDisabled={!isGlobalAccounts || !isActiveClient}
              data-testid="button_open-top-up"
            >
              <Icon as={PlusCircleIcon} mr={0.5} color="secondary.500" />
              {t('accounts:accountInfo.topUp')}
            </Button>
            {isFeautureExchangeEnabled && isDesktop && (
              <Button
                variant="primary"
                size="sm"
                onClick={onOpenExchangeModal}
                isDisabled={
                  !isGlobalAccounts ||
                  !isActiveClient ||
                  !account.allowOutgoingOperations
                }
              >
                <Icon as={ExchangeIcon} mr={0.5} color="secondary.500" />
                {t('accounts:accountInfo.exchange')}
              </Button>
            )}
          </HStack>
          {/* В рамках BANK-1136 удалил кнопку Details */}
          {isDesktop ? (
            <ActionsPopover placement="left-start" accountId={account.id}>
              <IconButton
                aria-label={t('accounts:accountEditName.ariaLabel')}
                icon={<Icon as={EllipsisIcon} />}
                variant="outlined"
                size="sm"
                w="40px"
                minW="40px"
                border="none"
                isDisabled={!isGlobalAccounts}
                data-testid="button_account-context-menu"
              />
            </ActionsPopover>
          ) : (
            <IconButton
              aria-label={t('accounts:accountEditName.ariaLabel')}
              icon={<Icon as={EllipsisIcon} />}
              variant="outlined"
              size="sm"
              w="40px"
              minW="40px"
              border="none"
              onClick={() => setIsMobileMoreShown(true)}
              isDisabled={!isGlobalAccounts}
              data-testid="button_account-open-menu"
            />
          )}
        </Flex>
      </VStack>
      <MobileMoreDrawer
        isOpen={isMobileMoreShown}
        onClose={() => setIsMobileMoreShown(false)}
      />
    </Box>
  );
};
