import { IconButton } from '@chakra-ui/react';
import { useHandleToastError } from '@payler/bank-utils';
import { useCallback } from 'react';
import { useTransferStatementQuery } from '../../hooks/reports/queries';
import { DocIcon } from '../../icons';
import { usePermissions } from '../../hooks/use-permissions';

export const TransferStatementButton = ({
  transferId,
}: {
  transferId: string;
}) => {
  const handleToastError = useHandleToastError();
  const { handleTransferStatementQuery, loading } = useTransferStatementQuery();
  const { isGlobalAccounts } = usePermissions();

  const handleDownloadStatement = useCallback(async () => {
    try {
      await handleTransferStatementQuery(transferId);
    } catch (e) {
      handleToastError(e);
    }
  }, [handleToastError, handleTransferStatementQuery, transferId]);

  return (
    <IconButton
      icon={<DocIcon width="18px" height="18px" />}
      aria-label="download"
      variant="outlined"
      border="none"
      w={6}
      h={6}
      onClick={handleDownloadStatement}
      isLoading={loading}
      data-testid="button_download_transfer-statement"
      isDisabled={!isGlobalAccounts}
    />
  );
};
