import React, { PropsWithChildren, FC, useMemo } from 'react';
import {
  DrawerBody,
  DrawerHeader,
  HStack,
  VStack,
  Text,
  Box,
  Flex,
  IconButton,
} from '@chakra-ui/react';
import { NoData, ThinDivider } from '@payler/ui-components';
import { TextStyles } from '@payler/ui-theme';
import { BankDrawer } from '../../components/BankDrawer/BankDrawer';
import { BankDrawerContent } from '../../components/BankDrawer/BankDrawerContent';
import { LoaderBox } from '../../components/LoaderBox/LoaderBox';
import {
  useSetFxTransferIdToUrl,
  useFxTransferIdFromUrl,
  useFxTransferByUrl,
} from '../../hooks/transfers/queries';
import { useLanguageFeatures } from '../../hooks/use-language-features';
import { TransferIconWithStatus } from '../../components/TransferIconWithStatus/TransferIconWithStatus';
import { useTranslation } from 'react-i18next';
import { CrossIcon } from '@payler/ui-icons';
import { InfoItem } from '../helpers/InfoItem';
import { TransferStatementButton } from '../../components/TransferStatementButton/TransferStatementButton';
import { isNil } from 'lodash';

export const FxTransferInfoDrawer = () => {
  const fxTransferId = useFxTransferIdFromUrl();
  const isOpen = !!fxTransferId;
  const setFxTransferId = useSetFxTransferIdToUrl();

  return (
    <BankDrawer isOpen={isOpen} onClose={setFxTransferId}>
      <BankDrawerContent drawerId="fx-transfer-info-drawer">
        <React.Suspense fallback={<LoaderBox />}>
          <HeaderWrapper>
            <Header />
          </HeaderWrapper>
          <ThinDivider />
          <Body />
        </React.Suspense>
      </BankDrawerContent>
    </BankDrawer>
  );
};

const HeaderWrapper: FC<PropsWithChildren> = ({ children }) => {
  const transfer = useFxTransferByUrl();
  const setFxTransferId = useSetFxTransferIdToUrl();

  return (
    <DrawerHeader p={3}>
      <HStack spacing={2} justifyContent="space-between">
        {children}
        <HStack spacing={2}>
          {transfer?.id && <TransferStatementButton transferId={transfer.id} />}
          <IconButton
            icon={<CrossIcon />}
            aria-label="close"
            variant="outlined"
            border="none"
            w={6}
            h={6}
            onClick={() => {
              setFxTransferId();
            }}
            data-testid="button_drawer-close"
          />
        </HStack>
      </HStack>
    </DrawerHeader>
  );
};

const Header = () => {
  const { serverDatetimeToDisplay } = useLanguageFeatures();
  const { t } = useTranslation();
  const transfer = useFxTransferByUrl();

  if (!transfer) {
    return <Box />;
  }

  return (
    <HStack spacing={2}>
      <Flex alignItems="center" position="relative">
        <TransferIconWithStatus status={transfer.state} type="fx" />
      </Flex>
      <VStack spacing={0} alignItems="baseline">
        <Text textStyle={TextStyles.h4} color="primary.500">
          {t('accounts:exchange.selectStep.exchangeDirection', {
            senderCurrency: transfer.senderSide.currency.toUpperCase(),
            receiverCurrency: transfer.recipientSide.currency.toUpperCase(),
          })}
        </Text>
        <Text textStyle={TextStyles.Subtitle14Regular} color="primary.350">
          {serverDatetimeToDisplay(transfer?.created, 'time')}
        </Text>
      </VStack>
    </HStack>
  );
};

const Body = () => {
  const { t } = useTranslation();
  const transfer = useFxTransferByUrl();
  const { formatAmountByCurrency, serverDatetimeToDisplay } =
    useLanguageFeatures();

  const amountCredited = useMemo(() => {
    if (transfer) {
      return formatAmountByCurrency(
        transfer.recipientSide.amount || 0,
        transfer.recipientSide.currency,
      );
    }
    return '';
  }, [transfer, formatAmountByCurrency]);

  const amountDebited = useMemo(() => {
    if (transfer) {
      return formatAmountByCurrency(
        transfer.senderSide.amount || 0,
        transfer.senderSide.currency,
      );
    }
    return '';
  }, [transfer, formatAmountByCurrency]);

  if (!transfer) {
    return <NoData />;
  }

  return (
    <DrawerBody p={3}>
      <VStack spacing={2} alignItems="stretch">
        <InfoItem
          label={t('transfers:date')}
          value={serverDatetimeToDisplay(transfer?.created, 'datetime')}
        />
        <InfoItem
          label={t('transfers:amountCredited')}
          value={amountCredited}
        />
        <InfoItem label={t('transfers:amountDebited')} value={amountDebited} />
        <InfoItem
          label={t('transfers:exchangeRate')}
          value={transfer.exchangeRate?.toString()}
        />
        {!isNil(transfer.recipientSide.feeAmount) && (
          <InfoItem
            label={t('transfers:fee')}
            value={formatAmountByCurrency(
              transfer.recipientSide.feeAmount,
              transfer.recipientSide.currency,
            )}
          />
        )}

        <InfoItem
          label={t('transfers:status')}
          value={t(`transfers:statuses.${transfer.state}`)}
        />
        <InfoItem
          label={t('transfers:chargeAccount')}
          value={transfer.sender.account.accountName}
        />
        <InfoItem
          label={t('transfers:depositAccount')}
          value={transfer.recipient.account.accountName}
        />
      </VStack>
    </DrawerBody>
  );
};
