const bSizes = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
const iBSizes = [
  'bytes',
  'KiB',
  'MiB',
  'GiB',
  'TiB',
  'PiB',
  'EiB',
  'ZiB',
  'YiB',
];

/**
 * Форматировать байты в человеко читаемый вид
 * @param bytes количество байт
 * @param decimals отображаемое количество знаков после запятой
 * @param formatType тип отображения. iB - это когда KiB = 1024 Bytes. B - это когда KB = 1000 Bytes
 * @returns отформатированное значение
 */
export function formatBytes(
  bytes: number,
  decimals = 2,
  formatType: 'B' | 'iB' = 'B'
) {
  if (bytes === 0) return '0 bytes';

  const k = formatType === 'iB' ? 1024 : 1000;
  const sizes = formatType === 'iB' ? iBSizes : bSizes;
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return (
    parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + ' ' + sizes[i]
  );
}
