import { css } from '@emotion/react';
import { FC, memo, useEffect, useMemo, useState } from 'react';
import { LoaderBox } from '../../components/LoaderBox/LoaderBox';
import {
  useOnboardingStagesQuery,
  useOnboardingStageStepQuery,
  useOnboardingStepMockQuery,
  useOnboardingStepQuery,
  useStartOnboarding,
} from '../../hooks/onboarding/queries';
import {
  useGetParamsFromCookies,
  useOnboardingStepParamFromUrl,
  useSetCookieParamsToUrl,
} from '../../hooks/onboarding/url';
import { useAnalyticsManager } from '@payler/analytics';
import { useGetAxiosError } from '@payler/bank-utils';
import { SuccessPage } from './SuccessPage';
import { PaymentPage } from './PaymentPage';
import { CreatingAccountPage } from './CreatingAccountPage';
import { usePermissions } from '../../hooks/use-permissions';
import { useNavigate } from 'react-router-dom';
import { useOnboardingStages } from '../../hooks/onboarding/use-onboarding-stages';
import { RejectionPage } from './RejectionPage';

const iframeStyle = css`
  width: 100%;
  height: 100vh;
  border: none;
`;

const loaderBoxStyle = css`
  position: absolute;
`;

export const UTM_LIST = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
];

export const checkStartOnboardingError = (errorCode: string) => {
  return errorCode === 'ONBOARDING_NOT_STARTED';
};

export const OnboardingPage: FC = () => {
  const stepParamFromUrl = useOnboardingStepParamFromUrl();
  // Убираем параметр текущего шага чтобы пользователь не имел возможности переходить на предыдущие
  // шаги
  if (stepParamFromUrl !== undefined) {
    const url = new URL(window.location.href);
    url.searchParams.has('nextStep') && url.searchParams.delete('nextStep');
    window.history.replaceState(null, '', url);
  }

  const getError = useGetAxiosError();
  const { isGlobalAccounts } = usePermissions();
  const { checkDemoAccess, getCurrentStage } = useOnboardingStages();
  const navigate = useNavigate();
  const {
    data: onboardingStages,
    error,
    isError,
  } = useOnboardingStagesQuery({ isRefetchingAllowed: false });
  const { mutate: startOnboarding, isLoading: isStartOnboardingLoading } =
    useStartOnboarding();
  const [isIframeLoaded, setIframeIsLoaded] = useState(false);
  const analyticsManager = useAnalyticsManager();

  const currentStage =
    onboardingStages && getCurrentStage(onboardingStages.stages);
  const isDemoAccess = useMemo(
    () => !!onboardingStages && checkDemoAccess(onboardingStages.stages),
    [checkDemoAccess, onboardingStages],
  );

  const { data: onboardingStageStep } = useOnboardingStageStepQuery(
    currentStage?.type,
  );
  const currentStepType =
    stepParamFromUrl || onboardingStageStep?.currentStep?.type;

  const onboardingStepQuery = useOnboardingStepQuery;
  const onboardingStepMockQuery = useOnboardingStepMockQuery;
  // Костыль потому что Zoho Forms не может редиректить на относительный путь. Поэтому пришлось
  // добавить мок данные для режима разработки.
  const { data: onboardingStepData } =
    process.env.NODE_ENV === 'development'
      ? onboardingStepMockQuery(currentStepType)
      : onboardingStepQuery(currentStepType);

  // Добавление utm-меток из куки в url айфрейма
  const getParamsFromCookies = useGetParamsFromCookies();
  const setCookieParamsToUrl = useSetCookieParamsToUrl();
  const utmFromCookies = getParamsFromCookies(UTM_LIST);
  let iframeUrl =
    onboardingStepData?.iframeUrl ||
    onboardingStageStep?.currentStep?.iframeUrl ||
    '';
  if (iframeUrl) {
    const url = new URL(iframeUrl);
    iframeUrl = setCookieParamsToUrl(utmFromCookies, url).href;
  }

  // Если онбординг не был запущен, то запрос онбординга вернет ошибку, и надо сделать запрос на
  // запуск онбординга.
  useEffect(() => {
    if (
      isError &&
      !isStartOnboardingLoading &&
      checkStartOnboardingError(getError(error).errorCode)
    ) {
      startOnboarding();
    }
  }, [error, getError, isError, isStartOnboardingLoading, startOnboarding]);

  useEffect(() => {
    if (currentStepType?.toLowerCase() === 'documentsForm'.toLowerCase()) {
      analyticsManager.sendBriefSubmitEvent();
    } else if (
      currentStepType?.toLowerCase() === 'preScoringForm'.toLowerCase()
    ) {
      analyticsManager.sendDocumentSubmitEvent();
    } else if (
      currentStepType?.toLowerCase() === 'creatingAccount'.toLowerCase()
    ) {
      analyticsManager.sendPrescoringSubmitEvent();
    }
  }, [analyticsManager, currentStepType]);

  if (onboardingStages?.result === 'rejected') {
    return <RejectionPage />;
  }
  if (currentStepType?.toLowerCase() === 'creatingAccount'.toLowerCase()) {
    return <CreatingAccountPage />;
  }
  if (currentStage?.type === 'payment') {
    return <PaymentPage />;
  }
  if (
    currentStage?.type === 'accountActivation' ||
    onboardingStages?.status === 'completed'
  ) {
    if (isDemoAccess || isGlobalAccounts) {
      navigate('/');
    }
    return <SuccessPage />;
  }
  return (
    <>
      {!isIframeLoaded && <LoaderBox css={loaderBoxStyle} />}
      <Iframe
        iframeUrl={iframeUrl}
        onIframeLoad={() => setIframeIsLoaded(true)}
      />
    </>
  );
};

const Iframe: FC<{ iframeUrl: string; onIframeLoad(): void }> = memo(
  ({ iframeUrl, onIframeLoad }) => (
    <iframe
      frameBorder="0"
      title="Onboarding"
      css={iframeStyle}
      src={iframeUrl}
      onLoad={onIframeLoad}
    ></iframe>
  ),
);
