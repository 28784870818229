import { useTranslation } from 'react-i18next';
import { EventStatus } from '../../components/EventStatus/EventStatus';

export const SelfTransferWarning = ({ close }: { close: VoidFunction }) => {
  const { t } = useTranslation();

  return (
    <EventStatus
      statusType="warning"
      title={t('accounts:transferMoney.selfWarning.title')}
      description={t('accounts:transferMoney.selfWarning.description')}
      onCloseButtonClick={() => {
        close();
      }}
      closeButtonProps={{ variant: 'secondary' }}
    />
  );
};
