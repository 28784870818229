import { Text, useModalContext } from '@chakra-ui/react';
import { TextStyles } from '@payler/ui-theme';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { EventStatus } from '../../components/EventStatus/EventStatus';

export const ExchangeSuccess: FC = () => {
  const { onClose } = useModalContext();
  const { t } = useTranslation();

  const description = (
    <Text as="small" textStyle={TextStyles.Subtitle14Regular}>
      {t('accounts:exchange.successDescription')}
    </Text>
  );
  return (
    <EventStatus
      statusType="success"
      title={t('accounts:exchange.successTitle')}
      description={description}
      onCloseButtonClick={onClose}
    />
  );
};
