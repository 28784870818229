export const profile = {
  support: 'Support',
  name: 'Name',
  email: 'Email',
  mobilePhone: 'Mobile phone',
  address: 'Address',
  password: 'Password',
  legalInformation: 'Legal information',
  closeAccount: {
    closeAccount: 'Close account',
    title1: 'Are you sure about closing your account?',
    description1:
      'If you set up your account for closing, you won’t be able to receive or make payments and your IBAN\\SWIFT credentials would become invalid',
    title2: 'Your account is about to be closed',
    description2: 'Please, wait for a customer support team to contact you.',
  },
  personalInfo: 'Personal info',
  companyName: 'Company Name',
  mainCurrency: 'Main currency',
  registrationDate: 'Registration Date',
  status: 'Status',
  countryOfRegistration: 'Country Of Registration',
  taxResidencyCountry: 'TAX Residency Country',
  registrationNumber: 'Registration Number',
  phoneNumber: 'Phone Number',
  companyInfo: 'Company info',
};
